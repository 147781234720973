.pw-tip {
  color: $theme-black;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 10px;
}

.forget-modal {
  .modal-footer {
    border-top: 0;
    padding: 0;
  }
}

.update-parameter-modal {
  .modal-content {
    padding: 35px 35px 58px !important;
  }
  form {
    .capital-cost-select {
      top: 33px !important;
    }
  }
  .modal-header {
    .close-btn {
      top: 6px !important;
      right: 0px !important;
      .icon-close-grey-icon::before {
        font-size: 18px !important;
    }
    }
  }
  .modal-head {
    padding-bottom: 30px;
    font-size: 37px;
    line-height: 44px;
  }
  .modal-footer {
    justify-content: center !important;
    border-top: 0px;
  }
}

// setting-modal start here
.setting-modal {
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 0px;
  }

  .top-right-modal {
    position: absolute;
    right: 35px;
    top: 35px;
    margin: 0;
    padding-bottom: 30px;

    &.input-output-modal {
      .modal-content {
        max-height: initial;
        min-height: initial;

        .modal-footer {
          width: 100%;
          position: initial;
          bottom: 35px;
          right: 35px;
          z-index: 5;
        }

      }
    }

    &.data-table-modal {
      position: static;

      .modal-content {
        padding: 35px 35px 58px;
      }

      .data-table-main {
        .data-table-inner {
          .data-table-title {
            font-size: 22px;
            font-weight: 400;
            margin-bottom: 20px;
          }

          .data-table-row-wrap {
            max-height: 480px;
            overflow-y: auto;
            padding-right: 29px;
            margin-right: -29px;
          }

          .data-table-row {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            padding-bottom: 20px;
            margin-bottom: 20px;

            &:last-child {
              border-bottom: 0;
              padding-bottom: 0px;
              margin-bottom: 0px;

            }

            .data-table-row-head {
              font-size: 12px;
              font-weight: 700;
              text-transform: uppercase;
              margin-bottom: 15px;
              display: block;
              margin-top: 0px;
            }

            .data-table-inner-wrap {
              display: flex;
              justify-content: space-between;

              .data-table-col {
                .data-table-head {
                  color: $text-grey;
                  font-size: 14px;
                  font-weight: 700;
                  margin-bottom: 11px;
                }

                .data-table-value {
                  color: $text-grey;
                  font-size: 12px;
                  font-weight: 500;
                  margin-bottom: 22px;

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }

      }
    }

    &.add-user-modal {
      &.modal-dialog-centered {
        position: static;
        padding-bottom: 0;
        margin: auto;

        .modal-content {
          max-height: 523px;
          min-height: 523px;

          .modal-header {
            .btn-close {
              top: 36px;
              right: 36px;
            }
          }

          .modal-body {
            .modal-head {
              &.modal-head-medium {
                font-size: 24px;
                margin-bottom: 32px;
                font-weight: 400;
              }
            }

            .modal-head-des {
              margin-bottom: 30px;
              color: rgb(90, 91, 94, 0.7);
            }

            .send-invite-btn {
              padding: 20px 32px;
              font-size: 16px;
              margin: 0 auto;
              filter: drop-shadow(13.1592721939px 13.1592721939px 23.4986972809px rgba(24, 57, 87, 0.15)) drop-shadow(-10.8093996048px -10.8093996048px 17.1499996185px #fff);
            }

            .form-group {
              margin-bottom: 24px;
            }
          }
        }

        .dropdown-wrapper.shared-profile {
          .user-icon {
            .user-img {
              object-fit: cover;
              border-radius: 50%;
            }
          }
        }

        .custom-select-wrp {
          .select__control {
            border: 1px solid #5a5b5e;
            height: 54px;
            background: transparent;
            border-radius: 0;
            font-weight: 500;
            font-size: 14px;
            padding: 10px 20px 10px 18px;
            color: #5a5b5e;

            .select__value-container {
              .select__single-value {
                color: $theme-black;
                font-weight: 500;
                font-size: 14px;
              }
            }

            .select__indicators {
              .select__indicator {
                &.select__dropdown-indicator {
                  &::after {
                    color: $theme-black !important;
                  }
                }
              }
            }
          }

          .select__menu {
            margin-top: 3px;
            left: 0;

            .select__menu-list {
              max-height: 160px;

              @include computer-max-1440 {
                max-height: 130px;
              }

              .select__option {
                padding-left: 30px;
                margin: 10px 0 16px;
                padding-bottom: 0px;
                font-size: 14px;

                &:last-child {
                  margin-bottom: 0;
                }

                &::before {
                  content: "";
                  width: 20px;
                  height: 20px;
                  background: url(../images/radio-default.svg) no-repeat center;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }

                &.select__option--is-selected {
                  color: $theme-black;

                  &::after {
                    content: "";
                    width: 20px;
                    height: 20px;
                    background: url(../images/radio-checked.svg) no-repeat center;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }
              }
            }
          }
        }

        .custom-select-main {
          position: relative;

          .tooltip-container {
            position: absolute;
            right: 50px;
            top: 46px;
            width: auto;

            .tooltip-text {
              width: 110px;
            }
          }
        }
      }
    }

    &.object-layers-modal {
      padding-bottom: 0;

      .modal-content {
        padding: 35px 0px 35px;

        .modal-body {
          .modal-head {
            &.modal-head-medium {
              margin-bottom: 0px;
              padding: 0 35px;
              font-size: 30px;
              font-weight: 400;
            }
          }
        }
      }
    }

    &.add-object-modal {
      right: inherit;
      left: 35px;

      .modal-content {
        .modal-body {
          .modal-head {
            &.modal-head-medium {
              margin-bottom: 15px;
            }
          }

          .add-object-inner {
            margin-bottom: 32px;
            overflow-y: auto;
            max-height: 769px;
            margin-right: 4px;
            padding: 0 31px 0 35px;

            .create-modal-left {
              padding: 15px 0 0 0;
              margin-bottom: 40px;
              max-width: 250px;

              .form-group {
                margin-bottom: 25px;

                &.file-input {
                  .file-input-wrapper {
                    display: block;

                    .file-upload-img {
                      min-width: 120px;
                      height: 120px;
                      width: 120px;

                      .error-msg {
                        width: 120px;
                      }
                    }

                    .file-input-text-wrapper {
                      margin-left: 0;
                      width: 100%;
                      display: flex;
                      justify-content: flex-start;
                      margin-top: 18px;
                      cursor: pointer;

                      .file-attachment-wrapper {
                        position: static;

                        .file-input-attachment {
                          font-size: 14px;
                          font-weight: 700;
                          color: $theme-black;
                          text-decoration: none;
                        }
                      }
                    }
                  }
                }
              }
            }

            .add-object-form-wrap {
              overflow-y: unset;
              max-height: initial;
              margin-right: 0;
              padding: 0;

              .form-group {
                margin-bottom: 24px;

                &.form-group-object {
                  input {
                    cursor: pointer;
                  }
                }
              }

              .custom-select-main {
                position: relative;
                margin-bottom: 24px;
              }

              .form-Control-wrapper {
                align-items: flex-end;

                .form-group {
                  width: calc(100% - 114px);
                  margin-right: 6px;
                  margin-bottom: 0;
                }
              }

              .add-parameter-main {
                .form-group {
                  .form-Control-wrapper {
                    .custom-select-main {
                      margin-bottom: 0;
                    }
                  }
                }

              }
            }

          }

          .modal-footer {
            padding-right: 35px;
          }
        }
      }
    }

    &.user-compamy-modal {
      &.modal-dialog-centered {
        .modal-content {
          padding-bottom: 35px;

          .modal-body {
            .dropdown-wrapper {
              align-items: flex-start;
              margin-bottom: 24px;

              .user-icon {
                min-width: 50px;
                width: 50px;
                height: 50px;
                font-size: 24px;
              }

              .right-sec {
                width: 100%;

                .name-last-edit {
                  display: flex;
                  justify-content: flex-start;
                  padding-right: 0px;
                  align-items: flex-end;

                  .dropdown-head {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-end;
                    width: calc(100% - 25px);
                    margin-bottom: 4px;

                    .name-first-last {
                      text-overflow: ellipsis;
                      overflow: hidden;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                      white-space: normal;
                      font-size: 24px;
                      font-weight: 400;
                    }

                    .edit-icon-wrap {
                      display: inline-block;
                      visibility: hidden;

                      .edit-icon {
                        width: 15px;
                        margin-bottom: 5px;
                      }
                    }

                    &:hover {
                      .edit-icon-wrap {
                        visibility: visible;
                      }
                    }

                  }

                  .dropdown-des {
                    &.email {
                      font-size: 14px;
                    }
                  }
                }
              }

              .add-uder-form {
                .component-input {
                  input {
                    height: 54px;
                    min-width: 230px;
                    padding: 10px 15px;
                  }
                }

                .setting-btn-wrap {
                  margin-top: 8px;
                }
              }

              &.user-name-email {
                .user-icon {
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                    object-position: center;
                  }
                }
              }
            }

            .custom-select-main {
              display: flex;
              align-items: center;
              margin-bottom: 24px;
              padding-bottom: 18px;
              border-bottom: 1px solid #D9D9D9;

              .form-label {
                width: 71px;
                margin-right: 3px;
                margin-bottom: 0;
                color: $theme-success;
                font-size: 16px;
                font-weight: 700;
              }

              .custom-select-wrp {
                max-width: 130px;
                height: auto;
                position: static;

                .select__control {
                  height: auto;
                  padding: 0;
                  border: 0;
                  width: 135px;

                  .select__value-container {
                    .select__single-value {
                      color: $theme-black;
                      font-size: 16px;
                      font-weight: 700;
                    }
                  }
                }

                .select__menu {
                  top: 30px;
                }
              }

              .tooltip-container {
                position: absolute;
                right: 137px;
                top: 8px;
                width: auto;
              }
            }

            .delete-compnay-btn-wrap {
              .deactive-activity-wrap {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                margin-bottom: 24px;
              }

              .delete-company-btn {
                align-items: flex-start;
                flex-direction: column;
                width: 100%;

                .deactive-user {
                  margin-bottom: 0px;
                }
              }
            }

            .setting-btn-wrap {
              position: absolute;
              right: 0;
              bottom: 0;
            }
          }
        }
      }
    }

    &.add-object-icon-modal {
      &.modal-dialog-centered {
        position: static;
        margin: auto;
        padding-bottom: 0;

        .add-object-icon-main {
          .dropdown-wrapper.shared-profile {
            .user-icon {
              box-shadow: none;
            }
          }
        }
      }
    }

    &.company-info-modal {
      &.modal-dialog-centered {
        .modal-content {
          min-height: 493px;
          max-height: 493px;
          padding: 35px 0;

          .modal-header {
            .btn-close {
              top: 41px;
            }
          }

          .modal-body {
            .dsider-tab-wrapper {
              .nav-tabs {
                margin: 0 35px 26px;
                height: 40px;

                .nav-item {
                  .nav-link {
                    font-size: 16px;
                    padding: 19px 19px;
                  }
                }
              }
            }

            .admin-tab-wrap {
              .modal-head {
                padding: 0 35px;
              }
            }

            .setting-btn-wrap {
              position: absolute;
              right: 0;
              bottom: 0px;
              padding-right: 35px;

              .btn-primary[type="submit"] {
                min-width: 157px;

              }
            }

            .edit-company-name-wrap {
              .edit-object-access {
                .access-objec-form-wrap {
                  margin-bottom: 11px;
                  padding: 0 28px 0 35px;
                  max-height: 290px;
                  overflow: auto;
                  margin-right: 5px;
                }

                .setting-btn-wrap {
                  bottom: 0px;

                  .send-invite-btn {
                    margin: 0;
                    min-width: 157px;
                  }
                }
              }
            }
          }

          .checkbox-wrapper {
            &.checkbox-grey-wrap {
              background: transparent;
              box-shadow: none;
              margin-bottom: 0px;
              max-height: 209px;
              padding: 0 31px 0 35px;
              margin-right: 4px;
              overflow-y: auto;

              .form-check {
                margin-bottom: 6px;
                margin-right: 0;
              }
            }

            .row {
              height: 209px;
            }
          }


        }
      }

      .company-name-wrap {
        margin-bottom: 24px;
        height: auto;
        padding: 0 35px;

        .compnay-logo-wrap {
          min-width: 50px;
        }

        .company-des-wrap {
          padding-right: 30px;

          .modal-head {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
        }
      }

      .user-card-main-wrap {
        margin-bottom: 36px;
        padding: 0 35px;

        .user-info-card {
          width: 172px;
          padding: 16px 21px 20px 24px;
        }

        .user-activity-card {
          width: calc(100% - 188px);
          padding: 16px 24px 20px 24px;
        }
      }

      .delete-compnay-btn-wrap {
        padding: 0 35px;

        .delete-company-btn {
          align-items: flex-start;
          flex-direction: column;

          .btn-no-outline {
            margin-bottom: 24px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .edit-user-permission {
            margin-bottom: 24px;
            color: $theme-success;
          }
        }
      }
    }

    &.input-variables-modal {
      .modal-content {
        .modal-body {
          .modal-head.modal-head-medium {
            font-size: 24px;
          }

          .checkbox-wrapper.checkbox-grey-wrap {
            margin-bottom: 0px;
            padding: 0;
            background: transparent;
            box-shadow: none;
            display: block;
          }

          .modal-footer {
            padding: 0 35px;
          }

          .objet-layer-main.add-object-icon-main {
            .object-ul.add-new-parameter-wrap {
              max-height: 380px;
              margin: 30px 4px 32px;
            }
          }
        }
      }
    }

    .modal-content {
      padding: 35px 35px 35px;
      border-radius: 40px;
      background-color: rgba(255, 255, 255, 0.85);
      backdrop-filter: blur(19.5px);
      min-height: initial;
      max-height: initial;

      .modal-header {
        position: initial;

        .btn-close {
          position: absolute;
          top: 25px;
          right: 25px;
          margin: 0;
          width: 18px;
          height: 18px;
          z-index: 5;
        }
      }

      .modal-body {
        .modal-head {
          &.modal-head-medium {
            font-size: 20px;
            margin-bottom: 16px;
          }

          &.modal-head-large-light {
            font-size: 22px;
            margin-bottom: 40px;
            font-weight: 300;
          }
        }

        .dsider-tab-wrapper {
          .nav-tabs {
            border: 1px solid #b4b4b6;
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            height: 40px;
            margin-bottom: 20px;

            .nav-item {
              width: 25%;
              flex: 1;

              &:nth-child(1) {
                border-right: 1px solid #b4b4b6;
                width: 25%;
              }

              &:nth-child(2) {
                border-right: 1px solid #b4b4b6;
              }

              &:nth-child(3) {
                border-right: 1px solid #b4b4b6;
              }

              &:last-child {
                border-right: 0px solid #b4b4b6;
              }

              .nav-link {
                width: 100%;
                min-width: 100%;
                font-size: 16px;
                padding: 20px 0px;
                border: 0;
                opacity: 0.4;
                border-radius: 0;
                margin-right: 0;

                &.active {
                  background-color: #5a5b5e;
                  border-radius: 0;
                  color: #fff;
                  opacity: 1;
                }
              }
            }
          }

          .tab-content {
            .tab-main-container {
              .operational-main-wrapper {

                &.loaction-map-wrap {
                  flex-direction: column;
                  margin-bottom: 0;
                }
              }

              .location-main-wrapper {
                .location-map {
                  width: 100%;
                  height: 277px;
                  border-radius: 30px;
                  background-color: #D9D9D9;
                  margin-bottom: 35px;
                }
              }

              .form-group {
                margin-bottom: 20px;

                &.error-field {
                  .error-msg {
                    font-size: 12px;
                    bottom: -18px;
                  }
                }

                .input-logo {
                  img {
                    width: 10px;
                  }
                }

                .form-control {
                  height: 40px;
                  padding: 10px 15px;
                }
              }

              .tab-main-wrapper {
                display: block;

                &:last-child {
                  padding-bottom: 0;
                }

                .financial-tab {
                  max-height: initial;
                  overflow-y: auto;
                  margin-right: -25px;
                  padding-right: 25px;
                  margin-bottom: 0px;

                  &.carbon-tab {
                    .setting-form-content {
                      margin-top: 12px;

                      .object-left-col {
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        width: 100%;
                        padding-right: 0;
                        margin-right: -25px;

                        &.row {
                          margin-bottom: 10px;
                        }
                      }
                    }
                  }

                  .checkbox-wrapper {
                    margin-bottom: 25px;
                  }
                }
              }
            }
          }
        }
      }

      .modal-footer {
        position: initial;
        z-index: 5;

        .btn-no-outline {
          padding: 0 35px 0 0;
          color: $text-error;
          min-width: auto !important;
        }

        .btn-primary {
          margin: 0;
          font-size: 14px;
          min-width: 156px;
          height: 50px;
        }
      }
    }
  }

  &.object-parameters-modal {
    .top-right-modal {
      right: 35px;
      top: 35px;
      margin: 0;
      padding-bottom: 30px;

      .info-logo {
        right: 68px;
        border: none;
        box-shadow: none;
        position: absolute;
        top: 20px;
      }

      .modal-content {
        padding-bottom: 105px;

        .operational-main-wrapper {
          max-height: initial;
          overflow-y: auto;
          min-height: initial;
          margin-right: -28px;
          padding-right: 23px;
          margin-bottom: 0 !important;
          padding-bottom: 0 !important;

          form {
            width: 100%;

            .object-form-wrap {
              .row {
                padding-top: 10px;
              }
            }
          }

          .object-parameter-form {
            padding-right: 28px;
          }

          .modal-footer {
            position: absolute;
            bottom: -70px;
            right: 0px;
            left: inherit;
            z-index: 5;

            .btn-primary {
              filter: drop-shadow(13.159272193908691px 13.159272193908691px 23.49869728088379px rgba(24, 57, 87, 0.05)) drop-shadow(-10.809399604797363px -10.809399604797363px 21.148826599121094px rgba(246, 246, 246, 0.9));
            }
          }

          .object-form-wrap {
            .form-group {
              .tooltip-container {
                .tooltip-text {
                  top: 72%;
                }
              }
            }
          }
        }

        .modal-body {
          .modal-header {
            position: absolute;
            top: -25px;
            right: 0;

            .info-logo {
              top: 21px;
              right: 40px;
            }

            .btn-close {
              top: 27px;
              right: 0px;
            }
          }

          .dsider-tab-wrapper {
            .nav-tabs {
              .nav-item {
                width: 33.33%;
              }
            }

            .tab-content {
              .tab-main-container {
                .tab-main-wrapper {
                  .financial-tab {
                    max-height: 540px;
                    overflow-y: auto;
                    min-height: 540px;
                    margin-right: -28px;

                    @include max-height-768 {
                      max-height: 360px;
                      min-height: 360px;
                    }

                    @include max-height-700 {
                      min-height: 300px;
                      max-height: 300px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.google-map-modal {
    .modal-dialog {
      position: static;
      margin: auto;
      padding-bottom: 0;

      .modal-content {
        padding-bottom: 35px;

        .modal-body {
          .place-search-box {
            right: 60px;
          }
        }
      }
    }

    .modal-title {
      font-size: 22px;
      font-weight: 300;
      margin-bottom: 20px;
    }
  }

  &.ev-setting-modal {
    .modal-546 {
      width: 100%;
      max-width: 546px;

      .modal-content {
        .modal-body {
          .modal-head.modal-head-medium {
            margin-bottom: 30px;
          }

          .ev-setting-wrap {
            .simulate-btn-wrap {
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
          }
        }
      }
    }
  }

  &.users-modal {
    .admin-tab-wrap {
      .nav-tabs {
        margin: 0 35px 30px !important;
      }
    }

    .modal-content {
      padding: 35px 0;

      .modal-body {
        .modal-head {
          &.modal-head-medium {
            padding: 0 35px;
          }
        }

        .dsider-tab-wrapper {
          .tab-content {
            .tab-main-container {
              overflow-y: auto;
              max-height: 500px;
              padding: 0 35px;
              margin-right: 5px;

              @include max-height-768 {
                max-height: 370px;
              }

              &::-webkit-scrollbar {
                width: 5px;
                height: 5px;
              }

              .tab-main-wrapper {
                display: flex;
                align-items: center;
                margin: 0px 0 30px 0;
                border-bottom: 0;
                padding-bottom: 0;

                &:last-child {
                  margin-bottom: 0;
                }

                .tab-left-part {
                  .dropdown-wrapper {
                    &.shared-profile {
                      .user-icon {
                        box-shadow: none;

                        &.small {
                          width: 36px;
                          height: 36px;
                          min-width: 36px;

                          .user-img {
                            height: 100%;
                          }
                        }

                        &.large {
                          width: 40px;
                          height: 40px;
                          min-width: 40px;
                        }
                      }

                      .right-sec {
                        .dropdown-head {
                          font-weight: 400;
                          font-size: 14px;
                        }

                        .dropdown-des {
                          font-weight: 400;
                          color: rgba(90, 91, 94, 0.6);
                        }
                      }
                    }
                  }
                }

                .tab-right-part {
                  .btn-no-outline {
                    &.success {
                      color: $theme-success !important;
                      font-weight: 400;
                      font-size: 14px;
                    }

                    &.danger {
                      font-size: 14px;
                      font-weight: 400;
                    }
                  }
                }

              }
            }
          }
        }
      }
    }
  }

  &.edit-permission-modal {
    .top-right-modal {
      &.modal-dialog-centered {
        position: static;

        .modal-content {
          padding: 35px 35px 35px;

          .modal-header {
            margin-bottom: 32px;

            .btn-close {
              top: 40px;
            }
          }

          .modal-body {
            .dsider-tab-wrapper {
              .nav-tabs {
                margin-bottom: 32px;

                .nav-item {
                  &:nth-child(1) {
                    width: 33.33%;
                  }

                  &:nth-child(2) {
                    width: 33.33%;
                  }

                  &:last-child {
                    width: 33.33%;
                  }
                }
              }

              .checkbox-wrapper {
                &.checkbox-grey-wrap {
                  padding: 0;
                  background: transparent;
                  box-shadow: none;

                  .form-check {
                    margin-right: 0;
                    margin-bottom: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.add-new-company-modal {
    .top-right-modal {
      &.add-user-modal {
        &.modal-dialog-centered {
          .modal-content {
            max-height: 550px;
            min-height: 550px;
            padding: 35px 0;

            .modal-body {
              .modal-head.modal-head-medium {
                margin-bottom: 28px;
                padding: 0 35px;
              }

              .add-company-form-wrap {
                margin-bottom: 8px;
                padding: 0 35px;
                margin-right: 0px;

                .form-group {
                  &.file-input {
                    width: 180px;
                    display: flex;
                    flex-direction: column;

                    .file-input-wrapper {
                      align-items: flex-start;
                      flex-direction: column;

                      .file-upload-img {
                        min-width: 150px;
                        width: 150px;
                        height: 150px;
                      }

                      .file-input-text-wrapper {
                        margin-left: 0;
                        margin-top: 10px;

                        .file-attachment-wrapper {
                          .file-input-attachment {
                            font-size: 14px;
                            font-weight: 500;
                          }

                          .file-input-des {
                            margin-top: 8px;
                          }
                        }

                        &:hover {
                          .file-input-attachment {
                            font-weight: 600;
                          }
                        }

                      }
                    }
                  }
                }

                .create-modal-right {
                  .object-modal-input {
                    position: relative;

                    &::after {
                      border-top: 0.45em solid;
                      vertical-align: 0.04em;
                      color: $theme-black !important;
                      display: inline-block;
                      margin-left: 0.255em;
                      content: "";
                      border-right: 0.3em solid transparent;
                      border-bottom: 0;
                      border-left: 0.3em solid transparent;
                      position: absolute;
                      right: 20px;
                      top: 50px;
                    }
                  }

                  .form-group {
                    &.access-object-modal {
                      input {
                        cursor: pointer;
                      }

                      .form-label-wrapper {
                        display: block;
                      }
                    }
                  }

                  width: calc(100% - 212px);

                  textarea {
                    max-height: 68px;
                    min-height: 64px !important;
                  }

                  .custom-select-main {
                    margin-bottom: 24px;
                  }
                }

              }

              .delete-compnay-btn-wrap {
                position: absolute;
                right: 35px;
                bottom: 0;
              }

              .invite-btn-wrap {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;

                .edit-user-permission {
                  color: $theme-success;
                  margin-right: 24px;
                }

                .send-invite-btn {
                  margin: 0;
                }
              }

              .checkbox-wrapper {
                &.checkbox-grey-wrap {
                  padding: 0;
                  background: transparent;
                  box-shadow: none;

                }
              }

              .custom-select-wrp {
                .select__menu {
                  .select__menu-list {
                    .select__option {
                      &::before {
                        background: url(../images/checkbox-default-grey.svg) no-repeat center;
                      }

                      &.select__option--is-selected {
                        &::before {
                          background: url(../images/checkbox-checked-grey.svg) no-repeat center;
                        }

                        &::after {
                          background: none;
                        }
                      }
                    }
                  }
                }

                .select__control {
                  max-height: 72px;
                  min-height: 54px;
                  height: auto;
                }
              }


              .setting-btn-wrap {
                position: absolute;
                right: 0;
                bottom: 0px;

                .send-invite-btn {
                  margin: 0;
                }

                &.access-object-footer {
                  right: 35px;
                }
              }

              .dsider-tab-wrapper {
                .nav-tabs {
                  margin-bottom: 32px;
                }

                &.add-user-permission-tab {
                  .nav-tabs {
                    margin: 0 35px;
                  }

                  .checkbox-wrapper {
                    padding: 0 31px 0 35px;
                    max-height: 290px;
                    overflow-y: auto;
                    margin-right: 4px;

                    .row {
                      height: 290px;
                    }
                  }

                  .add-user-permission-footer {
                    right: 35px;
                  }
                }
              }

              .access-objec-form-wrap {
                padding: 0 28px 0 35px;
                max-height: 340px;
                overflow: auto;
                margin-right: 5px;
              }

              .edit-company-name-wrap {
                .add-company-form-wrap {
                  .create-modal-right {
                    width: 100%;
                  }
                }
              }
            }
          }
        }

        /* Change the white to any color */
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #eaeaea inset !important;
        }
      }
    }
  }
}

.modal-backdrop.show {
  z-index: 1055;
}

.modal-title-des {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;

  .title-des-bold {
    font-weight: 700;
  }
}

// share modal start here
.share-modal {
  .modal-dialog {
    .modal-content {
      padding: 50px 35px 50px 35px;
      min-height: auto;

      .modal-body {
        .modal-head-large {
          font-size: 22px;
          font-weight: 300;
          margin-bottom: 7px;
        }

        .tag-input {
          .tag-input-form {
            .form-group {
              margin-bottom: 3px;

              &.error-field {
                .error-msg {
                  font-size: 12px;
                  bottom: -18px;
                }
              }

              &.share-search-input {
                .form-label-wrapper {
                  .form-label {
                    display: none;
                  }
                }
              }

              .create-modal-dropdown {
                position: absolute;
                right: 15px;
                top: 18px;
                transform: translateY(-50%);
                z-index: 2;
              }
            }
          }
        }

        .email-input-wrapper {
          .tab-main-wrapper {
            border-bottom: 0;
            margin-top: 20px;
            padding-bottom: 0;

            .tab-left-part {
              .dropdown-wrapper {
                .right-sec {
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  justify-content: center;

                  .dropdown-head {
                    font-size: 14px;
                    font-weight: 700;
                    margin-bottom: 2px;
                  }

                  .dropdown-des {
                    font-weight: 400;
                    font-size: 12px;
                    margin-bottom: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 260px;
                  }

                }

                &.shared-profile {
                  .user-icon {
                    background-color: $theme-orange;
                  }
                }

                .user-icon {
                  background-color: $primary-color;
                  border: 1px solid #fff;
                  width: 30px;
                  height: 30px;
                  min-width: 30px;
                  font-size: 12px;
                  margin-right: 5px;
                }
              }
            }

            .tab-right-part {
              .create-modal-dropdown {
                position: relative;
                right: unset;
                top: unset;
                transform: none;

                .dropdown-toggle {
                  &::after {
                    border-top: 0.45em solid;
                    vertical-align: 0.04em;
                    color: #5a5b5e !important;
                    display: inline-block;
                    margin-left: 0.255em;
                    content: "";
                    border-right: 0.3em solid transparent;
                    border-bottom: 0;
                    border-left: 0.3em solid transparent;
                  }
                }

                .dropdown-menu {
                  width: 135px;

                  .dropdown-item {
                    margin-bottom: 20px;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }

              .disable-field {
                color: #88888a;
                text-align: right;
                font-size: 12px;
                font-weight: 600;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }

  &.approval-modal {
    .top-right-modal {
      padding-bottom: 0;

      @include max-height-600 {
        top: 0px;
      }

      &.permission-list-modal {
        @include max-height-600 {
          top: 0;
        }
      }
    }

    .modal-content {
      padding: 40px 0px 40px 0px;

      .modal-header {
        .btn-close {
          top: 47px;
          right: 35px;
          z-index: 1;
        }
      }

      .modal-body {
        .modal-head-large {
          margin-bottom: 12px;
          padding: 0 35px;
        }

        .approval-req-wrap {
          overflow-y: auto;
          max-height: 350px;
          min-height: 350px;
          padding: 12px 35px 0px;
          margin-right: 4px;

          &:first-child {
            box-shadow: none;
          }

          .tab-main-wrapper {
            border-radius: 6px;
            background-color: #FFF;
            padding: 12px 20px;
            box-shadow: 13.159px 13.159px 23.499px 0px rgba(24, 57, 87, 0.05), -10.809px -10.809px 21.149px 0px rgba(246, 246, 246, 0.90);
            margin-bottom: 16px;
            align-items: flex-start;
            height: auto;
            flex-wrap: wrap;

            .tab-left-part {
              width: calc(100% - 100px);
            }

            .tab-right-part {
              width: 97px;
              justify-content: space-between;
              flex-direction: column;
              align-items: flex-end;
              height: 100%;

              .status {
                cursor: default;

                &.rejected {
                  .tooltip-container {
                    .reload-logo {
                      position: relative;
                      right: -5px;
                      height: 12px;
                      top: -1px;
                    }

                    .tooltip-text {
                      width: 250px;
                      white-space: break-spaces;
                      right: -130px;
                      top: 130%;
                      left: inherit;
                    }
                  }
                }
              }
            }

            .reload-btn {
              margin-left: auto;

              .reload-logo {
                margin-right: 4px;
              }
            }

            .dropdown-wrapper {
              .right-sec {
                width: 100%;

                .dropdown-head {
                  font-size: 16px;
                  font-weight: 700;
                  margin-bottom: 0px;

                  .approver-email {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 210px;
                    display: inline-block;
                  }

                  .btn-primary {
                    display: inline-block;
                    margin: -17px 0 0px 8px;
                    padding: 0;
                  }

                  .show-edit {
                    visibility: hidden;
                  }
                }

                .dropdown-des {
                  font-size: 14px;
                  font-weight: 400;
                  margin-top: 5px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 100%;
                }
              }

              &:hover {
                .right-sec {
                  .dropdown-head {
                    .show-edit {
                      visibility: visible;
                    }
                  }
                }
              }
            }

          }

          .reload-btn-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            position: relative;
            top: -6px;

            .tooltip-container {
              .tooltip-text {
                z-index: 9;
                top: 120%;
                width: 400px;
                left: 100%;
              }
            }

            .reload-btn {
              font-size: 18px;
              text-decoration: underline;
              width: 100%;
              text-align: right;
              justify-content: flex-end;
              font-weight: 500;

              .reload-logo {
                margin-right: 8px;
              }

              &.rejection {
                text-decoration: none;
                justify-content: flex-start;
                padding-left: 8px;
                font-weight: 400;
                color: $theme-black;
              }
            }

          }

          .approval-search-wrap {
            position: relative;

            .search-form-group {
              background-color: transparent;
              margin-bottom: 16px;

              input {
                border-radius: 6px;
                border: 1px solid $theme-black;
                background: #FFF;
                height: 61px;
                font-size: 16px;
                padding: 20px 30px 20px 30px;
              }
            }

            .comment-box-wrap {
              top: 65px;
              left: 0;
              width: 100%;
              border-radius: 6px;
              background-color: #FFF;
              box-shadow: 13.159px 13.159px 23.499px 0px rgba(24, 57, 87, 0.05), -10.809px -10.809px 21.149px 0px rgba(246, 246, 246, 0.90);
              backdrop-filter: none;
              padding: 10px 5px 10px 0px;

              .hover-box {
                max-height: 243px;
                overflow-y: auto;
                padding: 16px 20px;
              }

              .tab-left-part {
                margin-bottom: 16px;
                cursor: pointer;

                &:last-child {
                  margin-bottom: 0;
                }

                .dropdown-wrapper {
                  &.shared-profile {
                    .user-icon {
                      font-size: 14px;

                      .user-img {
                        height: 100%;
                        border-radius: 50%;
                      }
                    }

                    .right-sec {
                      .dropdown-head {
                        font-weight: 700;
                      }

                      .dropdown-des {
                        font-size: 14px;
                        font-weight: 400;
                      }
                    }
                  }
                }
              }

            }
          }

          .modal-footer {
            margin-top: 30px;
          }

          &.rejection-wrap {
            max-height: inherit;
            min-height: inherit;
            padding: 0px 35px 0px;

            .form-group {
              margin-bottom: 6px;
            }

            .modal-footer {
              padding: 0;
            }
          }
        }

        .permission-modal {
          width: 100%;
          background: #E8EAEF;
          padding: 24px 27px 16px;

          .permission-item {
            position: relative;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
            padding-left: 25px;

            &::before {
              content: '';
              background: url(../images/theme-tick-grey.svg) no-repeat;
              position: absolute;
              left: 0;
              width: 10px;
              height: 7px;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          .no-permission {
            position: relative;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 8px;
            padding-left: 25px;
          }
        }

        .modal-footer {
          padding: 0 35px;
          margin-top: 20px;
        }
      }
    }
  }

  &.rejection-modal {
    .modal-content {
      .modal-body {
        .modal-head-large {
          margin-bottom: 30px;
        }

        .form-group {
          textarea {
            font-size: 16px;
            background-color: #eaeaea;
          }
        }
      }
    }
  }

  &.export-csv-modal {
    .modal-content {
      .modal-body {
        .approval-req-wrap {
          min-height: inherit;
        }
      }
    }

    .checkbox-wrapper.checkbox-grey-wrap {
      margin-bottom: 16px;
      padding: 0;
      background: transparent;
      box-shadow: none;

      .form-check {
        .form-check-label {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

  &.add-to-baseline-modal {
    .modal-content {
      padding: 0;
    }
  }

  .permission-list-modal {
    max-width: 704px;

    .close-btn {
      width: 22px !important;
      height: 22px !important;
      background-color: transparent;
      top: 12px !important;
      right: 12px !important;

      .icon-close-grey-icon {
        min-width: 18px;
        height: 18px;
        width: 18px;

        &:hover {
          background-color: transparent;
        }

        &::before {
          font-size: 12px;
          color: rgba(90, 91, 94, 0.5) !important;
        }
      }
    }
  }
}

// fullscreen modal
.change-pw-modal {
  &.create-modal {
    .modal-dialog {
      .modal-content {
        .modal-header {
          .icon-close-grey-icon {
            &::before {
              font-size: 16px;
            }
          }
        }

        .modal-body {
          padding-top: 43px;

          .modal-title {
            margin-bottom: 10px;
          }

          .create-modal-wrapper {
            &.create-scenario-wrapper {
              display: flex;
              justify-content: space-between;
              max-width: 844px;
              width: 100%;

              .create-modal-left {
                width: calc(100% - 385px);
                padding-right: 0px;

                .modal-left-img {
                  width: 413px;
                  height: 413px;

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }

              .create-modal-right {
                width: 385px;
                min-width: initial;

                .textarea-wrapper {
                  textarea {
                    height: 110px;
                  }
                }
              }
            }

            .create-project-inner {
              display: flex;
            }

            .create-modal-left {
              padding-right: 46px;

              &.create-project-left {
                padding-right: 65px;
              }

              .modal-left-img {
                position: relative;
                height: 420px;
                width: 420px;

                &.project-folder-image {
                  height: auto;
                  width: auto;
                  padding-top: 0px;
                }
              }
            }

            .create-modal-right {
              min-width: 385px;

              textarea {
                height: 130px;
              }

              .full-width-form {
                width: 100%;
                padding: 0;
                box-shadow: none;
                background-color: transparent;

                .sign-btn-wrapper {
                  justify-content: flex-start;

                  .btn-primary {
                    font-size: 16px;
                  }
                }
              }
            }

            &.edit-profile-wrapper {
              padding: 50px;
              background-color: #e8eaef;
              box-shadow: 13px 13px 23px rgba(24, 57, 87, 0.15),
                -11px -11px 21px #f6f6f6;
              margin-bottom: 30px;

              .create-modal-left {
                max-width: 191px;
                padding-right: 0;

                .form-group {
                  .file-input-wrapper {
                    flex-direction: column;
                    justify-content: flex-start;

                    .file-upload-img {
                      margin-bottom: 20px;

                      &.empty-img-bg {
                        background-color: #e36f49;
                        border: 1px solid transparent;

                        .user-icon {
                          color: #fff;
                          font-size: 56px;
                          font-weight: 600;
                        }
                      }

                      &.border-none {
                        background-color: #fff;
                      }
                    }

                    .file-input-text-wrapper {
                      display: flex;
                      justify-content: center;
                      margin-left: 0;

                      .file-attachment-wrapper {
                        text-align: center;
                      }
                    }
                  }
                }
              }

              .create-modal-right {
                padding-left: 26px;

                .full-width-form {
                  .form-group {
                    &:last-child {
                      margin-bottom: 46px;
                    }
                  }
                }
              }

              .sign-btn-wrapper {
                display: flex;
                justify-content: space-between;

                .btn-no-outline {
                  color: $text-danger;
                  cursor: not-allowed;
                }
              }
            }
          }
        }
      }

      &.observations-modal {
        .modal-content {
          height: 100%;
          min-height: auto;
          border-radius: 40px;
          background-color: rgba(255, 255, 255, 0.90);
          backdrop-filter: blur(19.5px);
          padding: 0px;

          .modal-header {
            position: relative;
            top: 0;
            right: 0;

            .close-btn {
              top: 10px;
              right: 32px;

              .icon-close-grey-icon {
                &::before {
                  color: $theme-black;
                  opacity: 0.5;
                  font-size: 14px;
                }
              }

              &:hover {
                background-color: transparent !important;

                .icon-close-grey-icon {
                  &::before {
                    opacity: 1;
                  }
                }

              }
            }
          }

          .modal-body {
            padding: 0;
            display: block;

            .modal-title {
              color: $theme-black;
              font-size: 22px;
              font-weight: 300;
              margin-bottom: 20px;
            }

            .create-modal-wrapper {
              padding: 45px 4px 35px 0;

              .create-modal-right {
                .full-width-form {
                  padding: 0 31px 0 35px;
                  max-height: 610px;
                  overflow-y: auto;

                  @include max-height-740 {
                    max-height: 480px;

                  }

                  .sign-btn-wrapper {
                    justify-content: flex-end;
                    margin-top: 32px;
                  }

                  textarea {
                    height: 110px;
                  }
                }
              }

            }

          }
        }

        &.pdf-generator-modal {
          .modal-content {
            padding: 35px;

            .modal-header {
              position: relative;
              top: 0;
              right: 0;

              .close-btn {
                top: -32px;
                right: -8px;

                .icon-close-grey-icon {
                  &::before {
                    color: $theme-black;
                    opacity: 0.5;
                    font-size: 14px;
                  }
                }

                &:hover {
                  background-color: transparent !important;

                  .icon-close-grey-icon {
                    &::before {
                      opacity: 1;
                    }
                  }

                }
              }
            }

            .modal-body {
              .create-modal-wrapper {
                padding: 0;

                .create-modal-right {}

              }
            }
          }

        }

      }

    }
  }

  &.edit-profile {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .modal-title {
            margin-bottom: 46px;
          }
        }
      }
    }
  }

  &.wizard-ques-section {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .modal-title-des {
            margin-bottom: 60px;
          }
        }
      }
    }
  }

  &.onboard-user-section {
    .modal-dialog {
      .modal-content {
        .modal-body {
          padding-bottom: 30px;

          .create-modal-wrapper {
            width: 100%;

            .admin-section {
              background: none;
              min-height: 100%;

              .admin-body {
                padding: 0;
                min-height: 100%;

                .admin-body-header {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin-bottom: 60px;
                  margin-top: 30px;

                  .onboard-title-wrap {
                    .admin-section-title {
                      font-size: 36px;
                      font-weight: 300;
                    }

                    .modal-title-des {
                      margin-bottom: 0;
                    }
                  }

                  .add-user-wrap {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.set_password {
    .form-content-main {
      flex-direction: column;
      padding-top: 75px !important;
      padding-bottom: 50px !important;

      .dsider-logo {
        margin-bottom: 50px;
      }

      .login-right {
        height: auto;

        .modal-content {
          background: none;
          backdrop-filter: none;
          padding: 0 50px;

          .modal-body {
            .login-header {
              padding: 0;
            }

            .sign-btn-wrapper {
              display: flex;
              justify-content: center;
              margin-top: 45px;
            }
          }
        }
      }
    }
  }

  .modal-dialog {
    .modal-content {
      backdrop-filter: blur(25px);
      background: url(../images/modal-bg.png);
      background-size: cover;
      background-position: center center;
      display: flex;
      justify-content: space-between;
      min-height: 100vh;
      position: relative;

      .modal-header {
        position: absolute;
        top: 40px;
        right: 81px;
      }

      .modal-body {
        display: flex;
        justify-content: flex-start;
        padding-top: 43px;
        padding-bottom: 50px;
        align-items: center;
        flex-direction: column;

        .modal-title {
          color: $theme-black;
          font-size: 45px;
          font-weight: 300;
          line-height: normal;
          margin-bottom: 55px;
        }

        .modal-title-des {
          color: rgba(90, 91, 94, 0.7);
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
          margin-bottom: 46px;
        }

        .full-width-form {
          width: 576px;
          box-shadow: 13px 13px 23px rgba(24, 57, 87, 0.15),
            -11px -11px 21px #f6f6f6;
          background-color: #e8eaef;
          padding: 58px 48px;

          .sign-btn-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 46px;
          }
        }
      }
    }
  }
}

.modal-dialog {
  width: 100%;

  .modal-content {
    border-radius: 0;
  }

  &.modal-934 {
    max-width: 934px;
    width: 100%;
  }

  &.modal-861 {
    max-width: 861px;
  }

  &.modal-843 {
    max-width: 843px;
    width: 100%;
  }

  &.modal-817 {
    max-width: 817px;
    width: 100%;
  }

  &.modal-804 {
    max-width: 804px;
  }

  &.modal-800 {
    max-width: 800px;
  }


  &.modal-771 {
    max-width: 771px;

    @include computer-max-1440 {
      max-width: 671px;
    }
  }

  &.modal-701 {
    max-width: 701px;
  }

  &.modal-680 {
    max-width: 680px;
    width: 100%;
  }

  &.modal-608 {
    max-width: 608px;
  }

  &.modal-424 {
    max-width: 424px;
    width: 100%;
  }

  &.modal-404 {
    max-width: 404px;
    width: 100%;
  }

  &.modal-589 {
    width: 589px;
    max-width: 100%;
  }

  &.modal-588 {
    width: 588px;
    max-width: 100%;
  }

  &.modal-503 {
    max-width: 503px;
    width: 100%;
  }

  &.modal-546 {
    max-width: 546px;
    width: 100%;
  }

  &.modal-588 {
    max-width: 588px;
    width: 100%;
  }


  &.modal-480 {
    max-width: 480px;
  }

  &.modal-458 {
    max-width: 458px;
  }

  &.modal-450 {
    max-width: 450px;
    width: 100%;
  }

  &.modal-444 {
    max-width: 444px;
  }

  &.modal-430 {
    max-width: 430px;
  }

  &.modal-418 {
    max-width: 418px;
  }

  &.modal-401 {
    max-width: 401px;
  }

  &.modal-370 {
    max-width: 370px;
  }

  &.modal-364 {
    max-width: 364px;
  }

  &.modal-350 {
    max-width: 350px !important;
  }

  &.modal-345 {
    max-width: 345px;
  }

  &.modal-317 {
    max-width: 317px;
  }

  &.modal-306 {
    max-width: 306px;

    .modal-content {
      padding: 24px 30px;
      border-radius: 14px;
    }

    .modal-login-title {
      font-size: 18px;
    }

    .modal-login-subtitle {
      @include font(12px, $fw-light, 112.5%, #000);
    }
  }

  &.modal-right {
    margin: 0;
  }

  &.modal-dialog-centered {
    justify-content: center;
    margin: 0 auto;
    min-height: 100%;
  }

  &.notification-modal {
    max-width: 451px;

    .modal-header {
      padding: 0;
      height: 16px;

      .btn-close {
        margin: -8px -8px 0 auto;
      }
    }

    .modal-content {
      padding: 32px;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(51px);
    }

    .modal-title {
      font-size: 24px;
      font-weight: $fw-light;
    }

    .form-layout {
      >div {
        >div:first-child {
          padding-right: 12px;
        }

        >div:last-child {
          right: 0;
        }
      }
    }

    .no-notification-col {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
      min-height: 394px;
      max-width: 290px;
      margin: 0 auto;
      padding-right: 16px;
    }
  }
}

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1 !important;
}

.modal-content {
  border: 0;
  border-radius: 30px;
  padding: 30px;
  background: rgba(255, 255, 255, 1);
  backdrop-filter: blur(25px);
  transition: transform 0.3s ease-out;

  .modal-body {
    padding: 0;

    .modal-head {
      color: $theme-black;
      text-align: left;
      font-size: 16px;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 3px;
    }

    .modal-text {
      color: $theme-black;
      font-size: 14px;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 20px;
    }

    .form-group {
      margin-bottom: 30px;
      position: relative;

      .password-toggle {
        position: absolute;
        right: 7px;
        bottom: 22px;

        .eye-off {
          position: relative;

          &::after {
            content: "";
            background: url(../images/eye-off.svg);
            position: absolute;
            right: 15px;
            bottom: -4px;
            width: 18px;
            height: 18px;
          }
        }

        .eye-on {
          position: relative;

          &::after {
            content: "";
            background: url(../images/eye-on.svg);
            position: absolute;
            right: 15px;
            bottom: -4px;
            width: 18px;
            height: 18px;
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: nowrap;
      padding: 0;
    }
  }

  .modal-header {
    padding: 0;
    border: 0;
    position: relative;
    align-items: flex-start;

    .modal-logo {
      height: 48px;
      width: 144px;
    }

    .btn-close {
      padding: 0;
      width: 16px;
      height: 16px;
      margin: -24px -18px 0 auto;
      opacity: 0.2;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.6;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.modal-login-title {
  font-size: 26px;
  font-weight: 700;
  line-height: 130%;
  text-align: center;
}

.modal-login-subtitle {
  font-size: 12px;
  margin-top: 2px;
}

.modal-btn-row {
  display: flex;
  align-items: center;
  justify-content: center;

  &.confirm-modal-btns {
    margin: 0 -8px;

    .btn {
      margin: 0 8px;
    }
  }

  &.mt-12 {
    margin-top: 12px;
  }

  .multi-select__control {
    min-height: 42px;
  }

  &.after-add {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: -24px;
      right: -16px;
      top: 0;
      bottom: -25px;
      background-color: #fff;
      z-index: -1;
    }
  }
}

.modal-form-container {
  position: relative;

  .modal-bg-img {
    position: absolute;
    right: 30px;
    top: 0;
  }
}

// Cropper modal

.search-filter-modal {
  max-width: 320px;

  &.subscriber-modal {
    .modal-content {
      border-radius: 14px;
      padding: 24px 20px 12px 24px;

      .btn-close {
        width: 14px;
        height: 14px;
        right: -2px;
      }
    }
  }

  .modal-content {
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
    padding: 24px 12px 24px 24px;
    overflow: hidden;

    .btn-close {
      margin: 0;
      position: absolute;
      top: -4px;
      right: 8px;
      z-index: 99;
    }
  }

  .modal-login-title {
    @include font(12px, $fw-medium, 112%, $theme-black);
  }
}

.search-form-group {
  background-color: #fff;
  z-index: 5;

  input {
    border: 0;
    box-shadow: 13.159272193908691px 13.159272193908691px 23.49869728088379px 0px #1839570d;
    padding: 10px 92px 10px 50px;
    font-size: 15px;
    color: $text-grey;
    height: 51px;

    &:focus {
      box-shadow: 13.159272193908691px 13.159272193908691px 23.49869728088379px 0px #1839570d;
    }
  }

  .icon-close-grey-icon {
    cursor: pointer;
    min-width: 25px;
    width: 25px;
    height: 25px;
    padding: 15px 15px 15px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-size: 12px;
    opacity: 1;
    position: absolute;
    top: 50%;
    right: 55px;
    transform: translateY(-50%);

    &:hover {
      background-color: #e4e4e4 !important;
    }
  }

  .dropdown-auto {
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;

    .dropdown {
      .dropdown-toggle {
        min-width: auto;
        width: auto;
        padding: 0;
        height: auto;
        background-color: transparent;
        box-shadow: none;
        border: 0;

        &::after {
          border: 0;
        }

        .search-dropdown-image {
          margin: 0;
        }
      }

      .dropdown-menu {
        width: 223px;
        border: 0;
        border-radius: 0;
        background-color: rgba(255, 255, 255, 0.75);
        backdrop-filter: blur(10px);
        margin-right: -15px;
        margin-top: 20px;
        padding: 20px;

        .dropdown-item-text {
          font-size: 13px;
          color: $theme-black;
          margin-bottom: 20px;
          padding: 0;
        }

        .dropdown-item {
          margin-bottom: 20px;
          padding: 0;
          font-size: 15px;
          font-weight: 500;
          transition: all 0.3s ease-in-out;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $theme-black;

          .icon-theme-tick {
            opacity: 0;

            &::before {
              font-size: 12px;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          &.active {
            color: $primary-color;
            background-color: transparent;
            font-weight: 400;

            &:hover {
              font-weight: 400;
            }

            .icon-theme-tick {
              opacity: 1;
            }
          }

          &:hover {
            font-weight: 600;
            background-color: transparent;
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }

    &.filter-applied::after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      top: -1px;
      right: -1px;
      background-color: $primary-color;
      border-radius: 50%;
      border: 1px solid #fff;
      z-index: 2;
    }
  }
}

.scenario-list-search {
  .icon-close-grey-icon {
    right: 10px;
  }
}

.blue-spinner {
  color: #4387b0;
}

// all models start here
.all-models-section {
  background: url(../images/all-modal-bg.png);
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;

  &.modal-type-section {
    .main-container {
      max-width: 920px !important;
      padding-top: 73px;
    }
  }

  .main-container {
    padding-top: 110px;
    max-width: 602px;
    width: 100%;

    .search-logo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
      opacity: 0.5;
    }

    .section-title {
      text-align: center;
    }
  }

  &.all-project-section {
    .main-container {
      max-width: 626px;

      .dsider-tab-wrapper {
        .dsider-tab-inner {
          margin-left: -12px;
          margin-right: -12px;
        }
      }
    }
  }

  &.single-project {
    .search-form-group {
      .form-control {
        padding: 10px 40px 10px 50px;
      }

      .icon-close-grey-icon {
        &::before {
          right: 20px;
        }
      }
    }

    .main-container {
      padding-top: 73px;

      .section-title {
        position: relative;
        font-size: 37px;

        .close-btn {
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          border: 0;
          width: 45px;
          height: 45px;

          .back-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.select-mode-wrapper {
  .form-label {
    display: block;
  }

  .select-mode {
    display: inline-flex;
    align-items: center;
    position: relative;

    &.error-field {
      .select-mode-box {
        border-color: $text-error;
      }
    }

    .select-mode-box {
      border: 1px solid #b4b4b6;
      width: 115px;
      height: 115px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      position: relative;
      cursor: pointer;

      &.checked {
        border: 1px solid $primary-color;
        z-index: 1;

        .icon-success-tick {
          display: block !important;
        }
      }

      &+.select-mode-box {
        margin-left: -1px;
      }

      img {
        margin-bottom: 10px;
      }

      .select-mode-des {
        color: $theme-black;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
        max-width: 80px;
      }
    }
  }
}

// operational start here
.operational-main-wrapper {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0px;
  max-height: initial;
  overflow-y: auto;
  margin-right: -28px;
  margin-left: 10px;
  padding-right: 25px;
  margin-bottom: 0px;
  flex-wrap: wrap;

  .operational-supply-wrap {
    width: 50%;

    &.gutter-margin-20 {
      padding-right: 20px;
    }

    .card {
      margin-bottom: 10px;
      border-radius: 8px;
      background: #FFF;
      box-shadow: 13.159px 13.159px 23.499px 0px rgba(24, 57, 87, 0.05), -10.809px -5.809px 21.149px 0px rgba(246, 246, 246, 0.90);
      border: 0;

      .card-body {
        color: #222;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        padding: 20px 12px 20px 12px;
        height: 44px;
        display: flex;
        align-items: center;

        .card-drag-logo {
          margin-right: 7px;
        }
      }
    }
  }

  .setting-btn-wrap {
    margin-top: 20px;
  }
}

.operational {
  width: 50%;
  padding-right: 12px;

  &.operational-right {
    width: 50%;
    padding-left: 12px;
    padding-right: 0;
  }
}

.supply-head {
  color: $theme-black;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
  display: block;
}

.operational-box {
  width: 100%;
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 8px;
  box-shadow: 13.15927px 13.15927px 23.4987px 0px rgba(24, 57, 87, 0.05),
    -10.8094px -10.8094px 21.14883px 0px rgba(246, 246, 246, 0.9);
  padding: 14px 12px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  .operational-equal {
    margin-right: 10px;
    color: $theme-black;
    font-weight: 700;
  }

  .operational-box-des {
    color: #222;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
}

.modal-type-card {
  .project-card {
    padding: 40px 25px 47px;
    width: 288px;
    height: 390px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &.coming-soon-card {
      .btn-outline {
        border: 0;
        color: $theme-success;

        &:hover {
          color: $theme-success;
        }
      }
    }

    &:hover {
      .project-card-img {
        width: 156px;
        height: 156px;
      }
    }

    .project-card-img {
      width: 156px;
      height: 156px;
      margin-bottom: 30px;

      .card-img {
        width: 100%;
        height: 100%;
      }
    }

    .project-card-content {
      .project-card-title {
        margin-bottom: 10px;
      }

      .project-card-des {
        font-size: 14px;
        margin-bottom: 16px;
        min-height: 45px;
        font-weight: 400;
        color: rgba(90, 91, 94, 0.7);
      }
    }

    .btn-outline {
      min-width: auto;
      font-size: 13px;
      height: 38px;
      padding: 11px 27px;
      border-color: $theme-black;

      &:hover {
        background-color: transparent;
        color: $theme-black;
      }
    }
  }
}

// modal close button
.icon-close-grey-icon {
  font-weight: 100;
  color: #fff;
  background-color: transparent !important;
  border: 0;
  box-shadow: none !important;
  min-width: 25px;
  height: 25px;
  width: 25px;
  padding: 0;
  opacity: 0.5;
  z-index: 99;
  border-radius: 50%;

  ::before {
    font-size: 16px;
  }
}

// confirmation alert
.confirmation-dialog {
  width: 100%;
  max-width: 352px;
  border-radius: 0;
  padding: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  position: relative;

  .close-btn {
    width: 22px !important;
    height: 22px !important;
    background-color: transparent;
    top: 12px !important;
    right: 12px !important;

    .icon-close-grey-icon {
      min-width: 18px;
      height: 18px;
      width: 18px;

      &:hover {
        background-color: transparent;
      }

      &::before {
        font-size: 12px;
        color: rgba(90, 91, 94, 0.5) !important;
      }
    }
  }

  .confirmation-title {
    color: $theme-black;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .confirmation-des {
    color: $theme-black;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 25px;

    span {
      font-weight: 800;
      text-decoration: underline;
    }
  }

  .discard-btn {
    background-color: $bg-error;
    filter: drop-shadow(13.159272193908691px 13.159272193908691px 23.49869728088379px rgba(24, 57, 87, 0.05)) drop-shadow(-10.809399604797363px -10.809399604797363px 21.148826599121094px rgba(246, 246, 246, 0.9));
    height: 50px;
    width: 180px;
    margin: 0 auto 25px;
    font-size: 14px;

    &:hover {
      background-color: $bg-error;
    }
  }

  .btn-no-outline {
    color: $theme-black;
    text-align: center;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      color: $theme-black;
    }
  }
}

.font-18 {
  font-size: 18px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-24 {
  font-size: 24px !important;
}

.font-28 {
  font-size: 28px !important;
}

.font-30 {
  font-size: 30px !important;
}

.font-33 {
  font-size: 33px !important;
}

.font-35 {
  font-size: 35px !important;
}

.font-40 {
  font-size: 40px !important;
}

.font-45 {
  font-size: 45px !important;
}

.input-output-main {
  .input-output-inner {
    margin-bottom: 30px;

    .input-img-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .input-img-border {
        border-radius: 21px;
        border: 1.5px solid #7A3BC9;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
        position: relative;
        padding: 12px 13px;

        .input-node-wrap {
          .input-node {
            width: 10px;
            height: 10px;
            background-color: #fff;
            border: 1.5px solid #7A3BC9;
            border-radius: 50%;
            position: absolute;
            left: -6px;
          }
        }

        .output-node-wrap {
          .output-node {
            width: 10px;
            height: 10px;
            background-color: #7A3BC9;
            border: 1.5px solid #fff;
            border-radius: 50%;
            position: absolute;
            right: -6px;
          }
        }

        .input-node-img {
          border-radius: 50%;
          width: 72px;
          height: 72px;
          font-size: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #222;
          text-align: center;
          background-color: #0172B6;
          box-shadow: 0px 0px 0px 2px #0172B6;
          filter: none;
          border: 1px solid #fff;

          img {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }

      }

      .input-img-des {
        margin-bottom: 0;
        color: $theme-black;
        font-size: 19px;
        font-weight: 300;
      }
    }
  }

  .operational-main-wrapper {
    display: block;
    padding-bottom: 0;
    max-height: initial;
    min-height: initial;
    overflow-y: unset;
    margin-right: 0;
    padding-right: 0;

    .input-output-inner-wrap {
      max-height: initial;
      min-height: initial;
      overflow-y: auto;
      margin-right: -25px;
      padding-right: 25px;
      padding-bottom: 0;
      flex-wrap: wrap;
      display: flex;
      margin-bottom: 36px;
      justify-content: space-between;

      .operational-box {
        padding: 9px 16px;
        position: relative;

        .btn-no-outline {
          color: $theme-success;
          font-size: 14px;
          font-weight: 400;

          &.delete-btn {
            position: absolute;
            right: 15px;
          }
        }
      }
    }

    .modal-footer {
      margin-top: 0 !important;
    }
  }
}

.turbine-height-modal {
  .custom-select-wrp {
    .select__control {
      border: 1px solid $theme-black;
      background: transparent;
      border-radius: 0;
      height: 54px;
      font-weight: 500;
      font-size: 14px;
      line-height: normal;
      padding: 10px 20px;
      color: #5a5b5e;

      .select__value-container {
        .select__single-value {
          color: $theme-black;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .select__indicators {
        .select__indicator {
          &.select__dropdown-indicator {
            &::after {
              color: $theme-black !important;
            }
          }
        }
      }
    }

    .select__menu {
      .select__menu-list {
        max-height: 186px;
      }
    }
  }
}

.scheduling-modal {
  .custom-select-wrp {
    .select__control {
      border: 1px solid $theme-black;
      background: transparent;
      border-radius: 0;
      height: 54px;
      font-weight: 500;
      font-size: 14px;
      line-height: normal;
      padding: 10px 20px;
      color: #5a5b5e;

      .select__value-container {
        .select__single-value {
          color: $theme-black;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .select__indicators {
        .select__indicator {
          &.select__dropdown-indicator {
            &::after {
              color: $theme-black !important;
            }
          }
        }
      }
    }

    .select__menu {
      .select__menu-list {
        max-height: 186px;
      }
    }
  }
}

.schedule-error {
  color: $text-error;
}

.scheduling-select-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;

  .schedule-checkbox-label {
    margin-left: 8px;
    font-size: 14px;
    font-weight: 700;
  }

  .form-check-input[type="radio"] {
    box-shadow: none;
    cursor: pointer;
    background-color: transparent;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $theme-black;

    &:checked {
      &:after {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #5A5B5E;
        margin: 5px;
      }
    }

    &:focus {
      outline: none;
      border-color: none;
    }

  }

}

.schedule-universal-multiplier {
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;

  .schedule-universal,
  .schedule-multiplier {
    width: 45%;
  }
}

.input-with-unit {
  position: relative;
}

.scheduling-unit {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.month-label,
.yearly-label,
.daily-label,
.constant-label {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 14px;
}

.constant-input {
  .input-with-unit {
    width: 50%;
  }
}

.scheduling-checkbox-wrapper {
  display: flex;
  align-items: center;
  justify-content: start;
}

.scheduling-checkbox-wrapper:not(:first-of-type) {
  margin-left: 20px;
}

.scheduling-table-container {
  display: flex;
  justify-content: center;
}

.scheduling-table-wrapper {
  width: 100%;
  height: 400px;
  overflow-x: auto;
}

.scheduling-table-wrapper table {
  width: 100%;
}

.scheduling-table-wrapper th,
.scheduling-table-wrapper td {
  padding: 8px;
  text-align: left;
}

.scheduling-table-wrapper th {
  font-weight: bold;
}

.tarbine-open-btn {
  width: 200px;
  margin: 0 auto;
}

.move-modal {

  .custom-select-cls {
    .select__control {
      border: 1px solid $theme-black;
      background: transparent;
      border-radius: 0;
      height: 54px;
      font-weight: 500;
      font-size: 14px;
      line-height: normal;
      padding: 10px 20px;
      color: #5a5b5e;

      .select__value-container {
        .select__single-value {
          color: $theme-black;
          font-size: 14px;
          font-weight: 500;
        }
      }

      .select__indicators {
        .select__indicator {
          &.select__dropdown-indicator {
            &::after {
              color: $theme-black !important;
            }
          }
        }
      }
    }

    .select__menu {
      .select__menu-list {
        max-height: 186px;
      }
    }
  }
}

.checkbox-wrapper {
  &.checkbox-grey-wrap {
    margin-bottom: 40px;
    padding: 24px;
    background: #E8EAEF;
    box-shadow: 13.159px 13.159px 23.499px 0px rgba(24, 57, 87, 0.15), -10.809px -10.809px 21.149px 0px #F6F6F6;

    .form-check {
      margin-bottom: 6px;
      margin-top: 6px;

      .form-check-input[type=checkbox] {
        background-color: transparent;
        background-image: url(../images/checkbox-default-grey.svg);
        background-size: 16px;
        background-position: center 0px;
        border-radius: 0;
        border: 0;
        width: 16px;
        height: 16px;

        &:checked {
          background-color: transparent;
          background-image: url(../images/checkbox-checked-grey.svg);
          background-size: 16px;
          background-position: center 0px;
        }
      }

      .form-check-label {
        font-weight: 500;
        margin-top: 0px;
      }
    }

    .monte-checkbox-wrap {
      margin-bottom: 18px;

      &:last-child {
        margin-bottom: 0;
      }

      .form-check {
        margin-bottom: 10px;
        margin-top: 0;
      }

      .checkbox-input-wrap {
        display: flex;
        align-items: center;

        .form-group {
          margin-left: 32px;
          margin-bottom: 0;

          .form-control {
            max-width: 124px;
          }
        }

        .custom-select-main {
          margin-bottom: 0;
        }
      }

      .form-check-input[type=checkbox] {
        background-image: url(../images/checkbox-grey-trans.svg);
        background-size: 24px;
        background-position: center 0px;
        width: 24px;
        height: 24px;

        &:checked {
          background-image: url(../images/checkbox-grey-trans-checked.svg);
          background-size: 24px;
        }
      }

      .form-check-label {
        font-weight: 600;
        font-size: 20px;
      }

      .grey-border-select .custom-select-wrp .select__control {
        min-width: 230px;
        margin-bottom: 2px;
      }

    }
  }

  &.checkbox-trans-wrap {
    .form-check {
      margin-bottom: 14px;

      .form-check-input[type=checkbox] {
        background-color: transparent;
        background-image: url(../images/checkbox-default-24.svg);
        background-size: 20px;
        background-position: center 7px;
        border-radius: 0;
        border: 0;
        width: 20px;
        height: 27px;

        &:checked {
          background-color: transparent;
          background-image: url(../images/checkbox-checked24.svg);
          background-size: 20px;
          background-position: center 7px;
        }
      }

      .form-check-label {
        font-weight: 500;
        margin-top: 6px;
      }
    }
  }

  &.remove-shadow {
    box-shadow: none;
    background: transparent;
    margin-bottom: 0;
  }

  &.checkbox-24 {
    .form-check {
      .form-check-input[type=checkbox] {
        background-image: url(../images/checkbox-default-24.svg);
        background-size: 16px;
        width: 16px;
        height: 16px;

        &:checked {
          background-image: url(../images/checkbox-checked24.svg);
          background-size: 16px;
          width: 16px;
          height: 16px;
        }
      }

      .form-check-label {
        font-weight: 400;
        margin-top: 0px;
        font-size: 16px;
      }
    }

  }

  &.radio-wrap-grey {
    .form-check .form-check-input[type=radio] {
      border: none;
      background-image: url(../images/radio-default-grey.svg);
      background-size: 20px;
      width: 20px;
      height: 34px;
      background-color: transparent;
      border-color: transparent;
    }

    .form-check .form-check-input[type=radio]:checked {
      background-image: url(../images/radio-checked-grey.svg);
      background-color: transparent;
      border-color: transparent;
      background-size: 20px;
      width: 20px;
      height: 34px;
    }
  }
}

.delete-compnay-btn-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .delete-company-btn {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .btn-no-outline {
      &.delete-comp {
        margin-right: 30px;
      }

    }
  }
}

// add compnay file input
.add-company-form-wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  .create-modal-left {
    width: 200px;

    .form-group {
      &.file-input {
        .file-input-wrapper {
          flex-direction: column;
          align-items: flex-start;

          .file-upload-img {
            margin-bottom: 20px;
          }

          .file-input-text-wrapper {
            display: flex;
            justify-content: center;
            margin-left: 0;

            .file-attachment-wrapper {
              text-align: center;
            }

            .form-control[type=file] {
              font-weight: 700;
            }
          }
        }
      }
    }

  }

  .create-modal-right {
    width: calc(100% - 230px);

    .form-group textarea {
      height: 109px;
    }
  }

}

.search-wrap {
  padding: 0 23px;

  .search-form-group {
    input {
      padding: 10px 42px 10px 46px;
    }

    .icon-close-grey-icon {
      padding: 12px;
    }

    .search-logo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
    }
  }
}

.modal-open .dashboard-right .workbench-chart-main {
  backdrop-filter: none;
}

.user-icons {
  background-color: $theme-orange;
}

.tab-max-300 {
  max-height: 320px !important;
}

.text-captilize {
  text-transform: capitalize;
}

.default-cursor {
  cursor: default !important;
}

.edit-name-last {
  .edit-icon-wrap {
    display: inline-block;
    visibility: hidden;

    .edit-icon {
      width: 15px;
      margin-bottom: 5px;
    }
  }

  &:hover {
    .edit-icon-wrap {
      visibility: visible;
    }
  }
}

.add-parameter-main {
  .form-group {
    .form-Control-wrapper {
      .form-group {
        margin-bottom: 0;
      }
    }

    .edit-remove-btn-wrap {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      position: absolute;
      right: 0;

      .btn-no-outline {
        font-size: 14px;
        font-weight: 500;

        &.success {
          color: $theme-success;
        }

        &.remove {
          color: $text-error;
          margin-left: 16px;
        }
      }
    }
  }
}

// Reservair Modal start here

.reservair-modal {
  .modal-header {
    .btn-close {
      background: url(../images/modal-close-white.svg) no-repeat;
      background-size: 100%;
      width: 20px;
      height: 20px;
      opacity: 1;
      position: absolute;
      top: 10px;
      right: 5px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .modal-title {
    color: #FFF;
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 40px;

    @include computer-max-1400 {
      margin-bottom: 30px;
    }
  }

  .modal-content {
    background-color: rgba(16, 62, 141, 0.80);
    backdrop-filter: blur(40px);
    border-radius: 21px;
    padding: 35px 35px 20px;
  }
}

.Modal-wrap {
  .modal-table-inner {
    margin-bottom: 30px;

    @include computer-max-1400 {
      margin-bottom: 20px;
    }

    .modal-table-head {
      color: $white;
      font-size: 20px;
      font-weight: 300;
      line-height: 24px;
      margin-bottom: 8px;

      @include computer-max-1400 {
        font-size: 16px;
        margin-bottom: 2px;
      }
    }

    .modal-table-value {
      color: $white;
      font-size: 26px;
      font-weight: 300;
      line-height: 24px;

      @include computer-max-1400 {
        font-size: 24px;
      }
    }
  }
}
