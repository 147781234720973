.form-group {
  margin-bottom: 30px;
  display: grid;
  position: relative;

  &.disabled-field {
    cursor: not-allowed;
    opacity: 0.5;

    .form-control {
      cursor: not-allowed;

      &:focus {
        border-color: #5a5b5e;
      }
    }
  }

  &.edit-input {
    margin-bottom: 10px !important;
  }

  textarea {
    height: 150px;
    padding: 16px 20px;
  }

  &.multi-select-disabled {
    cursor: not-allowed;
  }

  &.file-input {
    .form-control[type="file"] {
      opacity: 0;
      height: 40px;
    }

    .file-input-wrapper {
      display: flex;
      align-items: flex-end;

      .file-upload-img {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 1px dashed #a0a0a0;
        min-width: 191px;
        height: 191px;

        .file-main-img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          position: absolute;
          background-color: #fff;
          z-index: 2;

          &+.file-main-img {
            display: none;
            z-index: 1;
          }

          &.empty-img {
            background-color: transparent;
          }
        }

        .cross-logo {
          position: absolute;
          top: -12px;
          right: -12px;
          z-index: 3;
          cursor: pointer;
        }
      }

      .file-input-text-wrapper {
        position: relative;
        margin-left: 35px;

        .file-attachment-wrapper {
          position: absolute;
          bottom: 0;

          .file-input-attachment {
            color: $primary-color;
            text-align: left;
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
            text-decoration-line: underline;
          }

          .file-input-des {
            color: $theme-black;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
            display: block;
            margin-top: 4px;
            text-decoration-line: none !important;
          }
        }
      }
    }
  }

  .validation-error {
    color: $text-error;
    text-align: right;
    font-size: 10px;
    font-weight: 500;
    line-height: normal;
    min-height: 15px;
    margin-top: 5px;
    padding-right: 8px;
  }

  &.error-field {

    .form-control,
    .multi-select__control,
    .textarea-wrapper {
      border-color: $text-error !important;
    }
  }

  &.passowrd-check {
    position: relative;
    display: block;

    .validation-error {
      position: absolute;
      right: 0;
    }

    .password-conditions-row {
      margin-bottom: 15px;
    }
  }

  .input-logo {
    position: absolute;
    right: 20px;
    bottom: 11px;
    cursor: pointer;
  }
}

.form-label-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-label {
  color: $theme-black;
  position: relative;
  width: auto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;

  &.disabled {
    color: rgba(90, 91, 94, 0.5);
  }

  .form-asterik {
    color: $text-error;
  }

  .label-info {
    font-size: 10px;
    position: absolute;
    top: 0;
    right: 25px;
    color: $text-error;
    font-weight: $fw-regular;

    &.black {
      color: $theme-black;
    }

    img {
      margin-right: 4px;
    }

    .icon-check-icon {
      font-size: 10px;
    }
  }
}
.group-separator{
  margin-bottom: 10px;
}
.char-count {
  color: $theme-black;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  min-width: 83px;
}

.form-control {
  border: 1px solid #5a5b5e;
  background: transparent;
  border-radius: 0;
  height: 54px;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  padding: 10px 20px;
  color: $theme-black;
  font-family: "Urbanist", sans-serif;

  &:focus {
    color: $theme-black;
    box-shadow: none;
    background: transparent;
    border-color: $primary-color;
  }

  &.error-field {
    border-color: $text-error;
  }

  &:disabled,
  &.disabled {
    background: transparent;
    color: #3c3c3c80;
    cursor: not-allowed;

    &::placeholder {
      color: #3c3c3c80;
    }

    .form-label {
      color: rgba(90, 91, 94, 0.5);
    }
  }

  &:-webkit-autofill {
    font-family: "Urbanist", sans-serif !important;
    font-weight: 500;
    font-size: 14px;
    -webkit-text-fill-color: $theme-black;
  }

  input {
    border: 0;
    width: 100%;
    outline: none;
  }
}

input,
textarea,
select {
  @include mobile-max {
    font-size: 16px;
  }
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
}

.setting-form-content {
  margin-top: 20px;
}

.form-check {
  display: flex;
  align-items: center;
  min-height: auto;
  margin-bottom: 0;
  margin-right: 30px;

  .form-check-input[type="checkbox"] {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid $theme-black;
    margin-right: 10px;
    margin-top: 0;
    background-color: transparent;
    outline: none !important;
    box-shadow: none !important;
    transition: 0.2s ease-in-out;
    cursor: pointer;

    &.mr-r-14 {
      margin-right: 14px;
    }

    &.error-check-input {
      border-color: $text-error;
    }

    &:checked {
      background-color: $checkbox-success;
      border-color: $checkbox-success;
      background-image: url("../images/white-tick.svg");
      background-size: 12px;
      background-position: center 7px;
    }
  }

  .form-check-label {
    color: $theme-black;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }

  &.form-switch {
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0 !important;

    .form-check-input {
      width: 34px;
      height: 19px;
      border-radius: 10px;
      border: 1px solid transparent;
      background-color: #4387B0;
      margin-right: 0;
      background-image: url("../images/form-switch.svg") !important;
      background-size: 16px !important;
      background-position: center left 1px;

      &:checked {
        background-position: center right 1px;
        background-color: #4387B0;
      }
    }

    .form-check-label {
      font-size: 12px;
      font-weight: 400;
      text-align: left;
    }

    &.counselor-student-switch {
      justify-content: flex-end;
      margin-bottom: 10px;

      .form-check-label {
        font-size: 14px;
        font-weight: 600;
        margin-right: 16px;
      }
    }
  }

  .form-check-input[type="radio"] {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid $theme-black;
    box-shadow: none;
    margin-top: 0;
    cursor: pointer;
    background-color: transparent;

    &:checked {
      border-color: $checkbox-success;
      background-color: $checkbox-success;
      background-image: url("../images/white-tick-radio.svg");
      background-size: 12px;
      background-position: center 7px;
    }
  }

  &.custom-grade-check {
    padding-left: 24px;

    .form-check-input {
      width: 24px;
      min-width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-left: -24px;
      transition: background-image 0.2s ease-in-out;

      &:hover {
        border-color: $theme-green;
        transition: border 0.2s ease-in-out;
      }

      &:checked {
        background-color: transparent;
        border: 0;
        background-image: url("../images/custom-radio-checked.svg");
        background-size: 24px;
        border-radius: 0;
      }
    }

    .form-check-label {
      font-size: 14px;
      font-style: italic;
    }
  }

  &.radio-check-grey {
    .form-check-input[type="radio"] {
      background-color: transparent;
      border-color: $theme-black;
      background-image: url("../images/radio-default-grey.svg");
      background-size: 20px;
      background-position: center 4px;
      border: none;
      height: 29px;

      &:checked {
        background-image: url("../images/radio-checked-grey.svg");
      }

    }
  }

  &.dsider-check-checkbox {
    margin-bottom: 0px;
    margin-right: 0;

    .form-check-input[type=checkbox] {
      width: 16px;
      height: 16px;
      border-radius: 0;
      margin-right: 12px;

      &:checked {
        background-color: $white;
        border-color: $theme-black;
        background-image: url(../images/checkbox-checked-grey.svg);
        background-size: 16px;
        background-position: center 0px;
        border: none;
        height: 16px;
      }

      &.parcially-checked {
        background-color: red;
      }
    }

    .form-check-label {
      font-weight: 500;
      text-transform: capitalize;
    }
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #3c3c3c33 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: normal !important;
  letter-spacing: normal !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #3c3c3c33 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: normal !important;
  letter-spacing: normal !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #3c3c3c33 !important;
  font-size: 14px;
  font-weight: 400;
  line-height: normal !important;
  letter-spacing: normal !important;
}

.otp-container {
  margin: 0 0 20px;
  justify-content: center;

  .otp-input {
    margin: 0 18px;
    border: 0 !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    outline: none;
    background-color: transparent !important;
    font-size: 16px;

    &:placeholder-shown {
      background-color: #3c3c3c33 !important;
    }

    &:focus {
      background-color: transparent !important;
    }

    &::placeholder {
      color: transparent !important;
    }
  }
}

.form-group-title {
  font-weight: 600;
  margin-bottom: 8px;
  display: inline-block;
}

select,
.form-select {
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-image: url("../images/select-dropdown.svg") !important;
  background-size: 12px 7px !important;
  background-repeat: no-repeat !important;
  background-position: center right 20px !important;
  padding-right: 45px !important;
}

.theme-card {
  .form-card-layout {
    padding: 16px 0 0;
    position: relative;

    @include medium-devices {
      padding: 16px 20px 0;
    }

    .complete-profile-bg {
      position: absolute;
      right: -30px;
      top: -38px;
    }
  }
}

.file-input-btn {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  background: transparent;
  font-size: 0;
  position: absolute;
}

// dropdown
.multi-select--is-disabled {
  cursor: not-allowed !important;
}

.multi-select__menu {
  border-radius: 20px !important;
  background: #fdfdfd !important;
  box-shadow: 5px 5px 20px 0px rgba(24, 57, 87, 0.08),
    -1px -1px 25px 0px rgba(239, 239, 239, 0.3) !important;
  backdrop-filter: blur(25px);
  border: 0 !important;
  margin: 0 !important;
  z-index: 9 !important;

  .multi-select__menu-list {
    border-radius: 20px !important;
    padding: 0 !important;

    // Custom Scrollbar
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $white;
    }

    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 10px;
    }
  }
}

textarea {
  resize: none;
  min-height: 86px !important;
}

.file-upload-btn {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  background: transparent;
  font-size: 0;
  position: fixed;
}

.select-with-scrollbar {
  .multi-select__menu {
    padding: 12px !important;

    .multi-select__menu-list {
      border-radius: 0 !important;
      max-height: 230px;

      .multi-select__option {
        padding: 8px !important;
        border-radius: 10px !important;

        &::after {
          left: 10px;
          width: calc(100% - 20px);
        }
      }
    }
  }
}

.d-done {
  display: none;
}

.search-form-group .form-control:focus+.icon-close-grey-icon::before {
  opacity: 0.5;
}

.search-form-group [type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

// custom select
.custom-select-main {
  margin-bottom: 45px;

  &.select-radio-grey {
    .custom-select-wrp {
      .select__menu {
        .select__menu-list {
          .select__option {
            padding-left: 30px;
            margin: 10px 0 16px;
            padding-bottom: 0px;

            &::before {
              content: "";
              width: 20px;
              height: 20px;
              background: url(../images/radio-default.svg) no-repeat center;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              background-size: 16px;
            }

            &.select__option--is-selected {
              color: $theme-black;

              &::after {
                content: "";
                width: 20px;
                height: 20px;
                background: url(../images/radio-checked.svg) no-repeat center;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-size: 16px;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  @include max-height-690 {
    margin-bottom: 30px;
  }
}

.custom-select-wrp {
  .select__control {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid $white;
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;

    &:hover {
      border-color: $white;
    }

    .select__value-container {
      padding: 0;
      transition: all 0.32s ease-in-out;

      .select__single-value {
        color: $white;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
      }
    }

    .select__indicators {
      .select__indicator {
        &.select__clear-indicator {
          display: none;
        }

        &.select__dropdown-indicator {
          padding: 0;

          svg {
            display: none;
          }

          &::after {
            border-top: 0.45em solid;
            vertical-align: 0.04em;
            color: $white !important;
            display: inline-block;
            margin-left: 0.255em;
            content: "";
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
          }
        }
      }

      .select__indicator-separator {
        display: none;
      }
    }
  }

  &.ccus-dropdown {
    .select__control {
      border-bottom: 1px solid #5a5b5e;
      .select__value-container {
        .select__single-value {
          color: #5a5b5e;
        }
      }
      .select__indicators .select__indicator.select__dropdown-indicator::after {
        color: #5a5b5e !important;
      }
    }
  }

  .select__menu {
    padding: 8px 5px 10px 20px;
    margin-top: 0px;
    transition: all 0.32s ease-in-out;
    border-radius: 0;
    z-index: 3;

    .select__menu-list {
      padding-right: 15px;
      padding-bottom: 9px;
      padding-top: 0px;
    }

    .select__option {
      color: $theme-black;
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
      transition: all 0.3s ease-out;
      position: relative;
      cursor: pointer;
      padding: 0;
      margin: 10px 0 20px;

      &:last-child {
        margin-bottom: 10px;
      }

      &.select__option--is-disabled {
        color: $theme-black;
        font-size: 13px;
        font-weight: 600;
        line-height: normal;
        cursor: default;
        opacity: 0.7;

        &:hover {
          font-weight: 600;
        }
      }

      &:hover {
        font-weight: 600;
        background-color: transparent;
      }

      &.select__option--is-focused {
        background-color: transparent;
      }

      &.select__option--is-selected {
        color: $primary-color;
        background-color: transparent;
        font-weight: 400;
        transition: all 0.5s ease-out;

        &::after {
          content: "";
          background: url(../images/theme-tick.svg) no-repeat;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 16px;
          height: 12px;
        }
      }
    }
  }
}

.select__multi-value__label {
  .label-text-des {
    display: none;
  }
}

.grey-border-select {
  .custom-select-wrp {
    .select__control {
      border: 1px solid #5a5b5e;
      height: 54px;
      background: transparent;
      border-radius: 0;
      font-weight: 500;
      font-size: 14px;
      padding: 10px 20px;
      color: #5a5b5e;

      .select__value-container {
        .select__single-value {
          color: #5a5b5e;
          font-weight: 500;
          font-size: 14px;
        }

      }

      .select__indicators {
        .select__indicator {
          &.select__dropdown-indicator {
            &::after {
              color: $theme-black !important;
            }
          }
        }
      }
    }
  }
}

.output-select {
  .select__control {
    padding: 2px 20px !important;
  }
}

// Multiselect start here
.multiselect-wrapper {
  margin-bottom: 65px;

  .multiselect-wrapper-inner {
    display: flex;
    width: 100%;

    &.checkbox-select {
      .custom-select-main {
        flex: 1;
        position: relative;

        .custom-select-wrp {
          .select__menu {
            width: calc(100% + 140px);
            padding: 9px 25px 9px 25px;

            .select__menu-list {
              padding-right: 15px;
              margin-right: -16px;
              margin-bottom: 0px;
              padding-bottom: 0;

              .select__option {
                position: relative;
                font-weight: 600;
                color: $text-grey;
                border-bottom: 1px solid rgb(0, 0, 0, 0.1);
                padding: 16px 0 16px 27px;
                margin: 0px 0 0px;
                align-items: center;
                display: flex;

                .checkbox-label-power {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 470px;
                }

                .label-text-des {
                  position: absolute;
                  right: 30px;
                  color: rgba(68, 68, 68, 0.6);
                  font-size: 12px;
                  font-weight: 500;
                }

                .shared-type {
                  position: absolute;
                  right: 30px;
                  top: 33px;
                  color: #0172b6;
                  font-size: 12px;
                  font-weight: 500;
                }

                &::before {
                  content: "";
                  width: 20px;
                  height: 20px;
                  background: url(../images/checkbox-unchecked.svg) no-repeat center;
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                }

                &.select__option--is-selected {
                  &::before {
                    content: "";
                    width: 20px;
                    height: 20px;
                    background: url(../images/checkbox-checked.svg) no-repeat center;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                  }
                }

                &:hover {
                  font-weight: 600;
                }

                &:last-child {
                  border-bottom: 0;
                }
              }
            }
          }

          .select__control {
            padding: 0px 20px 0 4px;
            height: 51px;

            .select__value-container {
              height: 51px;
              counter-reset: my-sec-counter;
              max-height: 51px;
              overflow-y: auto;
              padding: 0px 10px 0px 10px;
              margin-right: 20px;

              .select__multi-value {
                border: 1px solid $text-grey;
                border-radius: 4px;
                padding: 6px 12px 6px 12px;
                margin-right: 10px;
                position: relative;
                margin-bottom: 10px;
                margin-top: 10px;

                @include computer-max-1440 {
                  margin-right: 5px;
                  max-width: 150px;
                }

                &::before {
                  position: absolute;
                  width: 17px;
                  height: 17px;
                  border-radius: 50%;
                  border: 1px solid #fff;
                  left: -8px;
                  top: -8px;
                  background-color: #81bf7c;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 10px;
                  color: #fff;
                  counter-increment: my-sec-counter;
                  content: "" counter(my-sec-counter) "";
                }

                .select__multi-value__label {
                  color: $text-grey;
                  font-size: 14px;
                  padding: 0;
                  padding-left: 0;
                  box-sizing: border-box;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  max-width: 155px;

                  @include laptop-max-1366 {
                    max-width: 120px;
                  }
                }

                .select__multi-value__remove {
                  padding-left: 2px;
                  padding-right: 0px;

                  &:hover {
                    background-color: transparent;
                    color: rgba(0, 0, 0, 0.5);
                  }

                  svg {
                    color: rgba(68, 68, 68, 0.5);
                  }
                }
              }
            }
          }
        }

        .select-count {
          color: $text-grey;
          font-size: 14px;
          font-weight: 400;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 45px;
        }
      }
    }

    .custom-select-main {
      width: calc(100% - 140px);
      margin-bottom: 0;

      .custom-select-wrp {
        .select__menu {
          width: calc(100% + 140px);

          .select__menu-list {
            .select__option {
              position: relative;
              padding-left: 26px;
            }
          }
        }
      }
    }

    .btn.btn-primary.no-radius {
      width: 140px;
      min-width: 140px;
      height: 51px;
      font-size: 16px;
      background-color: $theme-success;
    }

    &.grey-border-select {
      .custom-select-main {
        background-color: transparent;
        filter: none;
        backdrop-filter: none;
        width: 100%;

        .custom-select-wrp {
          height: 54px;

          .select__menu {
            width: 100%;
            padding: 9px 20px 9px 20px;
            backdrop-filter: none;
            top: 110%;
            background-color: hsl(0, 0%, 100%);

            .select__menu-list {
              .select__option {
                font-size: 14px;
                font-weight: 500;
                padding: 8px 0 8px 27px;
                border-bottom: none;

                &::before {
                  background: url(../images/checkbox-default-grey.svg) no-repeat center;
                }

                &.select__option--is-selected {
                  &::before {
                    background: url(../images/checkbox-checked-grey.svg) no-repeat center;
                  }

                  &::after {
                    content: none;
                  }
                }

                &:hover {
                  font-weight: 500;
                }
              }
            }
          }

          .select__control {
            padding: 3px 20px 3px 12px;
            height: 54px;
            border-bottom: 1px solid #5a5b5e;

            .select__value-container {
              height: 44px;
            }

            .select__indicators {
              .select__indicator {
                &.select__dropdown-indicator {
                  &::after {
                    border-top: 0.45em solid;
                    vertical-align: 0.04em;
                    color: $theme-black !important;
                    display: inline-block;
                    margin-left: 0.255em;
                    content: "";
                    border-right: 0.3em solid transparent;
                    border-bottom: 0;
                    border-left: 0.3em solid transparent;
                    width: auto;
                    height: auto;
                    position: absolute;
                  }
                }
              }
            }
          }
        }

      }
    }

  }

  .custom-select-main {
    background-color: rgba(255, 255, 255, 0.85);
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.63));
    backdrop-filter: blur(25px);
    border: 0;
    position: relative;

    .custom-select-wrp {
      height: 51px;
      display: flex;
      align-items: center;

      .select__control {
        border-bottom: 0px solid #fff;
        padding: 17px 20px;
        width: 100%;

        .select__value-container {
          .select__single-value {
            font-size: 14px;
            font-weight: 400;
            color: $theme-black;
          }
        }

        .select__indicators {
          .select__indicator {
            &.select__dropdown-indicator {
              padding: 0;

              svg {
                display: none;
              }

              &::after {
                content: "";
                background: url(../images/select-dropdown-arrow.svg) no-repeat;
                position: absolute;
                top: 50%;
                right: 20px;
                transform: translateY(-50%);
                width: 13px;
                height: 8px;
                border: 0;
              }
            }

            .select__indicator-separator {
              display: none;
            }
          }
        }
      }

      .select__menu {
        background-color: rgba(255, 255, 255, 0.9);
        backdrop-filter: blur(10px);
        overflow-y: auto;
        padding: 9px 6px 9px 20px;

        .select__menu-list {
          max-height: 310px;

          @include max-height-740 {
            max-height: 250px;
          }
        }

        .select__option {
          margin: 0px 0 16px;

          &:last-child {
            margin-bottom: 0;
          }

          &.select__option--is-disabled {
            color: rgb(90, 91, 94, 0.6);
            margin: 0px 0 2px 0;

            &:first-child {
              margin: 10px 0 16px;
            }
          }
        }
      }
    }
  }
}

.form-Control-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .big-input {
    width: calc(100% - 114px);
    margin-right: 6px;
  }
}

.capital-cost-select {
  margin-bottom: 0;

  .custom-select-wrp {
    .select__control {
      border: 1px solid $theme-black;
      height: 54px;
      min-height: inherit;
      width: 114px;
      padding: 10px 10px 10px 10px;

      &:hover {
        border: 1px solid $theme-black;
      }

      .select__value-container {
        .select__single-value {
          color: $theme-black;
          font-size: 11px;
          font-weight: 400;
        }
      }

      .select__indicators {
        .select__dropdown-indicator {
          &::after {
            content: "";
            background: url(../images/select-dropdown-arrow.svg) no-repeat;
            position: absolute;
            top: 22px;
            right: 10px;
            width: 13px;
            height: 8px;
            border: 0;
            margin-bottom: 0;
          }
        }
      }
    }

    .select__menu {
      .select__option {
        font-size: 14px;
        font-weight: 500;
        margin: 10px 0 15px;

        &:hover {
          font-weight: 600;
        }

        &.select__option--is-disabled {
          color: rgba(90, 91, 94, 0.6);

          &:hover {
            font-weight: 500;
          }
        }

        &.select__option--is-selected {
          font-weight: 500;
        }
      }
    }
  }
}

.textarea-wrapper {
  border: 1px solid #5a5b5e;
  padding: 5px 5px 5px 0;

  textarea {
    border: 0;
  }
}

input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  height: 0;
  width: 0;
  margin: 0;
}

input[type="password"] {
  font-size: 14px;
  font-family: "Verdana", sans-serif !important;
  letter-spacing: 0.125em;

  &:placeholder-shown {
    font-size: 14px;
    font-family: "Urbanist", sans-serif !important;
  }
}

input[type="password"]:-webkit-autofill {
  font-family: "Verdana", sans-serif !important;
  letter-spacing: 0.125em;
}

// editable input form
.editable-input-form {
  .form-group {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.31);
    box-shadow: 13px 13px 25px 0px rgba(24, 57, 87, 0.15);
    backdrop-filter: blur(25px);
    min-height: 141px;
    margin-bottom: 20px;

    textarea {
      border: 0;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      height: 141px;
    }

    .sign-btn-wrapper {
      position: absolute;
      bottom: 12px;
      right: 12px;
      display: flex;

      button {
        border: 0;
        box-shadow: none;
        padding: 11px 9px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.31);
        backdrop-filter: blur(10px);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 38px;
        height: 37px;

        &.btn-correct {
          margin-right: 10px;
        }
      }
    }
  }
}

// Custom Range Slider
.scenario-list {
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  .color-box {
    width: 30px;
    height: 6px;
    background-color: $cyan-color;
    margin-right: 10px;

    &.purple {
      background-color: $purple-color;
    }

    &.light-green {
      background-color: $light-green-color;
    }

    &.orange {
      background-color: $orange-color
    }

    &.pink {
      background-color: $pink-color;
    }

    &.light-sky {
      background-color: $light-sky-color;
    }

    &.light-cyan {
      background-color: $light-cyan-color;
    }

    &.red {
      background-color: $red-color;
    }

    &.light-yellow {
      background-color: $light-yellow-color;
    }

    &.dark-red {
      background-color: $dark-red-color;
    }

    &.dark-purple {
      background-color: $dark-purple-color;
    }
  }

  .color-box-des {
    color: $theme-black;
    font-size: 12px;
    font-weight: 700;
  }
}

.pulse-stations-location {
  display: flex;
  justify-content: flex-start;
  padding: 0 !important;
  height: auto;
  margin-bottom: 0;
  flex-direction: column;
}

.pulse-stations-latitude,
.pulse-stations-longitude {
  margin-bottom: 20px;

  input {
    width: 100%;
    padding: 0;
    height: 30px;
    color: $theme-black;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    border: 0;
    border-bottom: 1px solid #5a5b5e;
  }
}

.pulse-stations-longitude-container {
  margin-left: 0;
}

.pulse-stations-latitude-container,
.pulse-stations-longitude-container {
  .form-label {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 0px;
    display: block;
  }
}

.zip-code-input {
  margin-bottom: 0;

  input {
    max-width: 80px;
    padding: 0;
    height: auto;
    color: $theme-black;
    text-align: right;
    font-size: 12px;
    font-weight: 700;
    border: 0px solid #5a5b5e;
  }
}

.ai-chat-switch-wrap {
  display: flex;
  align-items: center;

  .switch-btn-label {
    color: $theme-black;
    font-size: 16px;
    font-weight: 400;
    padding-right: 44px;
    opacity: 0.5;

    &.switch-btn-label-right {
      padding-right: 0px;
      padding-left: 10px;
    }

    &.active {
      opacity: 1;
      font-weight: 600;
    }
  }

  .form-switch {
    margin-right: 0;
    z-index: 2;
  }
}

.add-object-form-wrap {
  overflow-y: auto;
  max-height: 400px;
  margin-right: 4px;
  padding: 0 31px 0 35px;

  .form-group {
    &.location-form-group {
      input {
        padding: 10px 50px 10px 20px;
      }
    }

    .input-logo {
      &.location-img {
        bottom: 18px;
      }
    }
  }

  .btn-no-outline {
    &.new-parameter-btn {
      color: $theme-success;
      text-transform: uppercase;
      font-weight: 700;
      margin-top: 5px;

      &:hover {
        color: $theme-success;
      }
    }
  }

  .modal-footer {
    margin-top: 30px;
  }
}

.form-group-subhead {
  color: $theme-black;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 24px;
  margin-top: 15px;
}

.reason-char-limit {
  color: $theme-black;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  width: 100%;
  display: block;
}

.select__placeholder {
  color: rgba(90, 91, 94, 0.2) !important;
}

.zindex-4 {
  z-index: 12 !important;
}

.custom-select-main.object-select {
  display: flex;
  align-items: center;

  .custom-select-wrp {
    width: calc(100% - 35px);
  }

  .custom-search {
    background-color: rgba(255, 255, 255, 0.31);
    backdrop-filter: blur(10px);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    min-width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    cursor: pointer;
  }

  .search-object-form-group {
    width: 100%;
    margin-bottom: 0;

    .form-control {
      min-height: 38px;
      height: 38px;
      position: relative;
      background-color: transparent;
      border: 0;
      border-bottom: 1px solid #fff;
      border-radius: 0;
      box-shadow: none;
      cursor: pointer;
      align-items: center;
      display: flex;
      font-weight: 400;
      font-size: 22px;
      color: #fff;
      padding: 0 40px 0 0;

      &::placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
      }

      &::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
      }

      /* Specific placeholder color for Firefox 4-18 */
      &:-moz-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
      }

      /* Specific placeholder color for Firefox 19+ */
      &::-moz-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
      }

      /* Specific placeholder color for Internet Explorer 10+ */
      &:-ms-input-placeholder {
        color: rgba(255, 255, 255, 0.5) !important;
      }
    }

    .icon-close-grey-icon {
      position: absolute;
      right: 0;
      top: 11px;
      opacity: 1;

      &::before {
        color: #fff;
        font-size: 14px;
      }
    }
  }

}

.carbon-settings-form {
  .select__control {
    background-color: transparent;
    border: 1px solid #5A5B5E;
    margin-bottom: 20px;
    height: 54px;
    &:hover {
      border-color: #5a5b5e;
    }
    .select__value-container {
      .select__single-value, .select__placeholder {
        color: rgba(90, 91, 94, 1) !important;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        padding-left: 10px;
      }
    }
    .select__indicators {
      margin-right: 10px;
      .select__indicator {
      &.select__dropdown-indicator {
        &:after {
          color: #5a5b5e !important
        }
      }
    }
    }
  }
}