@import "variable.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

// Scroll start here
// Firefox

// Chrome, Edge, and Safari //
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 10px;
}

body {
  position: relative;
  font-family: $font-primary !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  color: $theme-black !important;
  -webkit-text-size-adjust: 100%; // 3
  -webkit-tap-highlight-color: rgba(black, 0); // 4
}

a {
  text-decoration: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent !important;
  transition: background-color 50000000s ease-in-out 0s;
}

// always show increase/decrease arrow in number input
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

h2,
h1,
h3,
h4,
h5 {
  color: $theme-black;
  font-family: "Urbanist", sans-serif !important;
  line-height: normal;
}
.groupName{
  text-transform: capitalize;
  font-size: 14px!important;
  font-family: "Urbanist", sans-serif !important;
  font-weight: 600;

}

p {
  color: $theme-black;
  font-family: "Urbanist", sans-serif !important;
  line-height: normal;
}

h5 {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.tag-input {
  .tag-input-form {
    display: flex;

    .form-group {
      width: 100%;
      height: 40px;
      margin-right: 1px;

      .icon-search-logo {
        position: absolute;
        left: 14px;
        top: 48%;
        transform: translateY(-48%);

        &::before {
          font-size: 17px;
        }
      }

      input {
        height: 40px;
        padding: 10px 98px 10px 33px;
        font-size: 12px;
      }
    }

    .no-radius {
      width: 70px;
      min-width: 70px;
      height: 40px;
      filter: drop-shadow(8.876301765441895px 8.876301765441895px 15.850536346435547px rgba(24, 57, 87, 0.05)) drop-shadow(-7.291245937347412px -7.291245937347412px 14.265481948852539px rgba(246, 246, 246, 0.9));

      .spinner-border {
        --bs-spinner-width: 16px;
        --bs-spinner-height: 16px;
      }
    }
  }
}

// tool tip css
.custom-tooltip {
  background-color: $theme-black !important;
  font-size: 11px !important;
}

.create-modal-dropdown {
  .dropdown-toggle {
    border-radius: 0;
    height: inherit;
    background: none;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
    min-width: inherit;
    padding: 0;
    margin: 0;
    display: inline-block;

    &.btn {
      background-color: transparent;
      box-shadow: none;
      backdrop-filter: none;
      color: #88888a;
      font-size: 12px;

      &:active,
      &:hover {
        background-color: transparent;
        color: #88888a;
      }
    }

    &::after {
      border-top: 0.4em solid;
      vertical-align: 0.1em;
      color: $theme-black !important;
    }
  }

  .dropdown-menu {
    width: 135px;
    background-color: rgba(255, 255, 255, 1);
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    border-radius: 0;
    padding: 20px 15px;
    top: calc(100% + 20px);
    left: 0;
    border: 0;

    .dropdown-item-text {
      padding: 0 0px 18px;
      font-size: 13px;
      font-weight: 600;
      line-height: normal;
      color: rgba(90, 91, 94, 0.6);
    }

    .dropdown-item {
      padding: 0 0px 20px;
      font-size: 12px;
      color: $theme-black;
      font-weight: 500;

      &:hover {
        background-color: transparent;
        color: $theme-black;
      }

      &:last-child {
        padding: 0 0px 0px;
      }
    }
  }
}

.tab-main-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 18px;

  &:last-child {
    border-bottom: 0;
    padding-bottom: 35px;
  }

  .tab-right-part {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.scenario-actions {
      justify-content: flex-end;

      .status {
        cursor: default;

        &.success {
          background-color: #4387B0;
        }
      }
    }

    .tab-date {
      color: #868586;
      text-align: right;
      font-size: 12px;
      font-weight: 500;
      line-height: normal;
      margin-right: 20px;
    }
  }
}

/* FullPageLoader.css */
.full-page-loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0px;
  width: 100%;
  z-index: 9999;
  display: none;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &.show {
    display: flex;
  }
}

.spinner-style {
  width: 22px;
  height: 20px;
  color: #0172b6;
}

.spinner-container {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.29);
  backdrop-filter: blur(10.780488014221191px);
  width: auto;
  height: 56px;
  padding: 15px 30px;
}

.loader-text {
  color: $white;
  font-size: 16px;
  font-weight: 700;
  margin-left: 15px;
}

.loader-spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #007bff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

.spinner-border {
  --bs-spinner-border-width: 2px;
  --bs-spinner-width: 26px;
  --bs-spinner-height: 26px;
}

.loader-animation {
  animation: imageRotating 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes imageRotating {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes loaderTopAnimation {
  0% {
    bottom: 0;
  }

  50% {
    bottom: 50px;
  }

  100% {
    bottom: 100px;
  }
}

@keyframes loaderBottomAnimation {
  0% {
    bottom: 100px;
  }

  50% {
    bottom: 20px;
  }

  100% {
    bottom: -60px;
  }
}


.confirmation-alert {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1025;
}

.confirmation-alert.show {
  display: flex;
}

.cursor-disabled {
  cursor: default;
}

.max-char {
  color: $white;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  margin-left: 20px;
  margin-top: -36px;
}

// Range Slider
.star-progress-bar {
  margin: 15px 0;
  padding: 0px 7px;

  .rc-slider-rail {
    height: 2px;
    background-color: rgba(90, 91, 94, 0.2);
    border-radius: 6px;
    cursor: pointer;
  }

  .rc-slider-track {
    height: 2px;
    background-color: $theme-black;
    border-radius: 6px;
  }

  .rc-slider-handle {
    background-color: $theme-black;
    border: solid 1px rgba(90, 91, 94, 0.2);
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-top: -8px;
    opacity: 1;

    @media (max-width: 576px) {
      width: 18px !important;
      height: 18px !important;
      margin-top: -8px !important;
    }

    &.active {
      box-shadow: 0 0 5px rgba(90, 91, 94, 0.2);
    }

    &:hover {
      border-color: rgb(215 219 229) !important
    }
  }

  .rc-slider-handle-dragging {
    border-color: rgba(90, 91, 94, 0.2) !important;
    box-shadow: 0 0 0 5px rgba(90, 91, 94, 0.2) !important;
  }

  .progress {
    height: 2px;
    background-color: #323232;
  }

  .progress-bar {
    width: 60%;
    background-color: #ff5c39;
    border-radius: 1px;
  }
}

.graph-right-head {
  color: $theme-black;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 20px;
  display: block;
}

.zip-code-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .zip-code-text {
    color: $theme-black;
    font-size: 12px;
    font-weight: 400;
  }

  .zip-code-value {
    color: $theme-black;
    text-align: right;
    font-size: 12px;
    font-weight: 700;
  }
}

.slider-wrap-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.ev-growth-inner {
    display: block;
    margin-bottom: 25px;

    .form-label {
      margin-bottom: 3px;
      font-size: 13px;
    }

    .slider-progress-value-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .slider-progress-value {
        width: 54px;
        height: 30px;
        border: 1px solid $theme-black;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $theme-black;
        font-size: 12px;
        font-weight: 400;
      }

      .star-progress-bar {
        width: 180px;
        padding: 0px 0px;
      }

    }

  }

  .slider-radius {
    color: $theme-black;
    font-size: 12px;
    font-weight: 400;
  }

  .star-progress-bar {
    width: 86px;
  }

  &.simulation-variables-wrap {
    display: block;

    .slider-progress-value-wrap {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .star-progress-bar {
        width: calc(100% - 54px);
        margin-right: 25px;
      }

      .slider-progress-value {
        width: 54px;
        height: 30px;
        flex-shrink: 0;
        border: 1px solid $theme-black;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $theme-black;
        font-size: 12px;
        font-weight: 400;
      }
    }

  }
}

.schedule-slider-progress-value-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .demand-slider-value {
    font-weight: 600;
    font-size: 12px;
  }

  .slider-radius {
    color: $theme-black;
    font-size: 12px;
    font-weight: 400;
  }

  .star-progress-bar {
    width: 65%;
  }

  &.simulation-variables-wrap {
    display: block;

    .slider-progress-value-wrap {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .star-progress-bar {
        width: calc(100% - 54px);
        margin-right: 25px;
      }

      .slider-progress-value {
        width: 54px;
        height: 30px;
        flex-shrink: 0;
        border: 1px solid $theme-black;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $theme-black;
        font-size: 12px;
        font-weight: 400;
      }
    }

  }
}

// AI chat css
.ev-chat-modal {
  .card {
    background: transparent;
    transition: .5s;
    border: 0;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-top: 30px;
    min-height: calc(100vh - 73px);
  }
}

.chat-app .people-list {
  width: 440px;
  position: initial;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  @include computer-max-1400 {
    width: 330px;
  }
}

.chat-app .chat {
  background-color: #E8EAEF;
  filter: drop-shadow(13.159272193908691px 13.159272193908691px 23.49869728088379px rgba(24, 57, 87, 0.15)) drop-shadow(-10.809399604797363px -10.809399604797363px 21.148826599121094px #F6F6F6);
  margin-left: 57px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 729px;
}

.people-list {
  -moz-transition: .5s;
  -o-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s
}

.people-list .chat-list li {
  padding: 10px 15px;
  list-style: none;
  border-radius: 3px
}

.people-list .chat-list li:hover {
  background: #efefef;
  cursor: pointer;
}

.people-list .chat-list li.active {
  background: #efefef;
}

.people-list .chat-list li .name {
  font-size: 15px;
}

.people-list .chat-list img {
  width: 45px;
  border-radius: 50%;
}

.people-list .about {
  float: left;
  padding-left: 8px;
}

.people-list .status {
  color: #999;
  font-size: 13px;
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f4f7f6;
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px;
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px;
}

.clearfix {
  &.chat-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

.chat-history-main {
  padding: 5px 5px 5px 0;
}

.chat .chat-history {
  width: 100%;
  padding-right: 5px;
  /* align-items: flex-end; */
  padding-bottom: 0px;
  padding-top: 10px;
  overflow: auto;
  height: calc(100vh - 300px);
}

.chat .chat-history .scrollbar {
  padding: 20px 30px 0px 30px;
  margin: 0;
  padding-right: 56px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  justify-content: flex-end;
}

.chat .chat-history ul {
  width: 100%;
  margin-bottom: 0;
  padding-left: 0;
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 15px;

  .my-message {
    margin-right: auto;
  }

  .other-message {
    margin-left: auto;
  }
}

.chat .chat-history ul li:first-child {
  margin-bottom: 15px;
}

.chat .chat-history .message-data {
  position: relative;

  .profile-icon {
    width: 25px;
    min-width: 25px;
    height: 25px;
    background-color: $theme-orange;
    border: 1px solid $white;
    margin-right: 0;
    position: absolute;
    top: -22px;
    right: -37px;
  }

  &.my-message-data {
    margin-right: auto;

    .message-data-time {
      padding-left: 0;
    }
  }
}

.chat .chat-history .message-data .chat-user-img {
  border-radius: 40px;
  width: 100%;
  padding: 6px;
}

.chat .chat-history .message-data-time {
  padding-left: 6px;
  color: rgba(112, 119, 137, 0.6);
  font-size: 12px;
  font-weight: 700;
}

.chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative
}

.chat .chat-history .my-message {
  padding: 15px 20px;
  background-color: $white;
  font-size: 14px;
  font-weight: 400;
  color: $theme-black;
  max-width: 381px;
  border-bottom-left-radius: 0;
  margin-bottom: 5px;
}

.chat .chat-history .other-message {
  text-align: left;
  padding: 15px 20px;
  background-color: #4387B0;
  filter: drop-shadow(13.159272193908691px 13.159272193908691px 23.49869728088379px rgba(24, 57, 87, 0.05)) drop-shadow(-10.809399604797363px -10.809399604797363px 21.148826599121094px rgba(246, 246, 246, 0.90));
  max-width: 381px;
  color: $white;
  font-size: 14px;
  font-weight: 400;
  border-bottom-right-radius: 0;
  margin-bottom: 5px;
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left: 93%;
}

.chat .chat-message {
  padding: 0px 30px 30px 30px;
  width: 100%;
  bottom: 0;
  background-color: #E8EAEF;
  position: initial;

  .chat-ai-form {
    position: relative;

    .ai-chatbox-textarea {
      padding: 18px 50px 10px 20px;
      border: 1px solid #5a5b5e;
      border-radius: 8px;
      overflow: hidden;
    }

    .form-control {
      color: $theme-black;
      max-height: 88px;
      height: 25px;
      overflow-y: auto;
      min-height: unset !important;
      padding: 0;
      border: 0px solid #5a5b5e;
    }

    .btn-primary {
      width: 30px;
      min-width: 30px;
      border-radius: 50%;
      height: 30px;
      padding: 0;
      position: absolute;
      right: 12px;
      bottom: 12px;

      .spinner-border {
        --bs-spinner-width: 14px;
        --bs-spinner-height: 14px;
      }
    }

  }
}

.online,
.offline,
.me {
  margin-right: 2px;
  font-size: 8px;
  vertical-align: middle;
}

.online {
  color: #86c541;
}

.offline {
  color: #e47297;
}

.me {
  color: #1d8ecd;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.ev-ai-chat-box1 {
  width: auto;
  height: auto;
  padding: 15px 15px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 30px;
  background-color: rgba(61, 133, 189, 0.2);
  border-radius: 8px;

  &::after {
    content: "";
    height: 0px;
    left: 15px;
    bottom: -12px;
    position: absolute;
    transform: rotate(270deg);
    width: 0px;
    border-left: 15px solid transparent;
    border-right: 8px solid transparent;
    border-top: 15px solid #c9d9e4;
  }

  &.ev-ai-chat-box2 {
    padding: 15px 24px 15px 24px;
    margin-left: 90px;
    margin-bottom: 40px;
    background-color: rgba(55, 151, 119, 0.2);
    display: flex;
    align-items: center;

    @include computer-max-1440 {
      margin-left: 40px;
      padding: 15px;
      margin-bottom: 30px;
    }

    .profile-icon {
      color: #379777;
      font-size: 12px;
    }

    .ev-ai-chat-des {
      padding-top: 0px;
      color: #379777;
      margin-bottom: 0;
      max-width: 200px;
    }

    &::after {
      content: "";
      height: 0px;
      left: auto;
      right: 40px;
      bottom: -11px;
      position: absolute;
      transform: rotate(238deg);
      width: 0px;
      border-left: 18px solid transparent;
      border-right: 8px solid transparent;
      border-top: 18px solid #c5d9d3;
    }
  }

  .ev-ai-chat-des {
    color: #3D85BD;
    font-size: 12px;
    font-weight: 400;
    padding-top: 0px;
    margin-bottom: 0;
  }

  .leaves-logo {
    width: 35px;
    height: 35px;
    min-width: 35px;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    margin-left: 20px;
  }
}

.ev-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 14px 23px 12px 23px;
}

.ev-ai-chat-title {
  font-size: 45px;
  font-weight: 300;
  margin-bottom: 12px;

  @include computer-max-1400 {
    font-size: 35px;
  }
}

.ev-ai-chat-des {
  color: rgba(90, 91, 94, 0.7);
  font-size: 16px;
  font-weight: 400;
}

.ev-chat-header-logo {
  margin-right: 7px;
}

.ev-chat-header-des {
  font-size: 14px;
  font-weight: 600;
  color: $theme-black;
}

.ev-chat-header-right {
  .btn {
    &.btn-no-outline {
      text-decoration: underline;
      color: $text-danger;
      font-size: 14px;
      font-weight: 700;

      &:disabled {
        background-color: transparent;
      }
    }
  }
}

.profile-icon-wrap {
  align-items: center;
  border-bottom: 0;
  padding-bottom: 0;
  margin-bottom: 10px;

  .profile-comment-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;

    .profile-icon {
      margin-right: 8px;
      width: 36px;
      min-width: 36px;
      height: 36px;
    }

    .comment-date {
      position: relative;

      .blue-spinner {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        --bs-spinner-width: 16px;
        --bs-spinner-height: 16px;
        right: 70px;
      }
    }
  }

  &.default-text-comment {
    margin-bottom: 12px;

    .profile-comment-wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.comment-user-name {
  color: #222;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 165px;
  margin-bottom: 4px;
  font-family: 'Urbanist', sans-serif;
}

.comment-date {
  color: $theme-black;
  font-size: 12px;
  font-weight: 300;
  display: block;
  font-family: 'Urbanist', sans-serif;
}

.comment-des {
  color: $theme-black;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  padding-left: 47px;
  word-break: break-word;
  font-family: 'Urbanist', sans-serif;
}

.profile-icon {
  margin-right: 11px;
  width: 35px;
  min-width: 35px;
  height: 35px;
  background-color: $white;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  text-transform: capitalize;
  color: $white;
  font-size: 12px;
  font-weight: 600;

  &.orange {
    background-color: $theme-orange;
  }
}

.ai-img-msg {
  width: 100%;
  height: 100px;
  display: inline-block;
  margin-top: 14px;
  position: relative;

  .ai-map-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 1px solid #EBEBEB;
  }

  .btn-primary {
    width: 180px;
    min-width: 180px;
    height: 44px;
    font-size: 13px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;

    .redirectArrow-chat {
      margin-left: 10px;
    }
  }
}

.highcharts-container {
// min-height: 550px !important;

  @include max-height-768 {
    min-height: 100% !important;
  }

  @include max-height-690 {
    // min-height: 100% !important;
    // max-height: 500px !important;
  }

}

.highcharts-menu {
  background: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(10px);
  padding: 20px 16px 4px !important;
  box-shadow: none !important;
  width: 230px !important;

  .highcharts-menu-item {
    color: $theme-black !important;
    font-family: 'Urbanist', sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: normal;
    padding: 0 0 15px 0 !important;
    transition: none !important;

    &:hover {
      font-weight: 600 !important;
      background: transparent !important;
      padding: 0 0 15px 0 !important;
      transition: none !important;
    }
  }
}

.highcharts-credits {
  display: none;
}

.power-graph-wrap {
  .highcharts-container {
    position: relative;

    &::after {
      content: " ";
      position: absolute;
      width: 70px;
      height: 15px;
      background-color: #ebecec;
      right: 10px;
      bottom: 0px;
    }

  }
}

.geoThermal-table-wrap {
  .highcharts-container::after {
    background: transparent;
  }

  .highcharts-legend {
    visibility: hidden;
  }

  .simulation-chart-wrapper {
    max-height: 530px;
    min-height: 530px;
  }
}

.zip-code-error-wrap {
  background-color: $bg-error;
  min-height: 68px;
  min-width: 424px;
  max-width: 480px;
  display: block;
  padding: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  z-index: 1024;

  .zip-code-error-msg {
    color: $white;
    font-size: 14px;
    font-weight: 500;
    padding: 6px 30px 0 30px !important;
    margin: 0;
  }

  .close-btn {
    top: 23px;
    right: 20px;
    width: 20px;
    height: 20px;
    padding: 0;

    &:hover {
      background-color: transparent !important;
    }

    .icon-close-grey-icon {
      &::before {
        color: rgba(255, 255, 255, 0.5);
        font-size: 12px;
      }
    }
  }

  &::before {
    content: "";
    background: url(../images/warning.svg) no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 20px;
    top: 23px;
  }

}

// Sidebar start here
.admin-section {
  background: rgba(255, 255, 255, 0.9) url(../images/all-modal-bg.png);
  background-size: cover;
  background-position: center center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: auto;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);

  .section-title {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 36px;
  }
}

.admin-body {
  width: 100%;
  padding: 56px 72px 50px 305px;
  min-height: 100vh;

  @include computer-max-1440 {
    padding: 30px 30px 20px 269px;

  }

  .search-form-group {
    max-width: 555px;
    width: 100%;

    input {
      width: 100%;
      max-width: 555px;
      background-color: $white;
      padding: 10px 60px 10px 50px;
      height: 60px;
    }

    .icon-close-grey-icon {
      right: 20px;
    }

    .search-logo {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 20px;
    }

    @include computer-max-1440 {
      max-width: 480px;

      input {
        width: 480px;
      }
    }
  }

  .table-wrapper {
    position: relative;

    .pagination-wrap {
      bottom: 32px;
    }
  }
}

.admin-sidebar {
  width: 275px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  height: 100%;
  z-index: 2;

  .dsider-logo-wrap {
    margin: 56px 0 40px 0px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }

  @include computer-max-1440 {
    width: 237px;

    .dsider-logo-wrap {
      margin: 32px 0 32px 0px;

      img {
        width: 150px;
      }
    }
  }

}

.admin-sidebar-wrap {
  display: flex;
  flex-direction: column;
  border: 0;

  &.nav-tabs {
    .nav-item {
      color: $theme-black;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;

      .nav-link {
        border: 0;
        padding: 23px 40px;
        color: $theme-black;

        &.active {
          border-radius: 0;
          background-color: #4387B0;
          width: 100%;
          color: $white;
        }

        @include computer-max-1440 {
          font-size: 14px;
          padding: 23px 30px;
        }
      }
    }
  }
}

.add-user-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  .search-form-group {
    margin-bottom: 0;
  }

  .btn-primary {
    @include computer-max-1440 {
      font-size: 14px;
    }
  }
}

// Pagination Start here
.pagination-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 32px;


  .pagination {
    margin-bottom: 0;
  }
}

.page-item {
  width: 22px;
  height: 22px;
  min-width: 22px;
  border: 0;
  margin: 0 8px;
  border-radius: 50%;

  .page-link {
    font-size: 12px;
    font-weight: 400;
    border: 0;
    color: $theme-black;
    background-color: transparent;
  }
}

.page-link.active,
.active>.page-link {
  border-radius: 50%;
  background-color: $theme-success;
  color: $white;
  border: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 100%;
  margin-top: 1px;
}

// user company modal
.company-name-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  height: 26px;

  &.user-company-status {
    align-items: flex-start;

    .compnay-logo-wrap {
      min-width: 40px;
      width: 40px;
      height: 40px;
      display: none;
    }

    .company-des-wrap {
      .company-name-des {
        color: $theme-success;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0px;
        display: inline-flex;

        .company-name-email {
          color: $theme-black;
          margin-left: 4px;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          white-space: normal;
        }
      }

      .modal-head-des {
        color: $theme-black;
        font-size: 16px;
        font-weight: 500;
        display: none;
      }
    }

  }
}

.compnay-logo-wrap {
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 50%;
  background: #fff;
  margin-right: 16px;

  .company-logo-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
}

.company-des-wrap {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  .modal-head {
    margin-bottom: 4px !important;
  }

  .modal-head-des {
    color: rgba(90, 91, 94, 0.7);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0 !important;
  }
}

.last-activity {
  color: rgba(0, 0, 0, 0.22);
  font-size: 13px;
  font-weight: 700;
  width: 100%;
  display: block;
  text-align: right;
}

.pac-container {
  z-index: 9999 !important;
}

.pac-item {
  cursor: pointer !important;
}

.sortable {
  cursor: pointer;
}

//  Accordian start here
.accordion {
  .accordion-item {
    border: 0;
    border-radius: 0;
    background-color: transparent;
    border-bottom: 1px solid #D9D9D9;

    .accordion-header {
      .accordion-button {
        margin-bottom: 0;
        box-shadow: unset;

        &::after {
          background-image: url(../images/dropdown-icon.svg);
          background-size: 10px;
          width: 13px;
          height: 10px;
        }

        .dsider-check-checkbox {
          .form-check-input[type=checkbox] {
            background-color: transparent;

            &:checked {
              background-color: transparent;
              background-image: url(../images/checkbox-checked-transparent.svg);
            }
          }

          &.parcially-checked {
            .form-check-input[type=checkbox] {
              &:checked {
                background-image: url(../images/checkbox-minus.svg);
              }
            }
          }
        }
      }
    }

    .accordion-button {
      border: 0;
      background-color: transparent;
      border-radius: 0;
      outline: none;
      font-size: 14px;
      font-weight: 500;
      color: $theme-black;
      padding: 20px 0;
      box-shadow: unset;

      &:focus {
        box-shadow: unset;
      }
    }

    .accordion-body {
      padding: 0px 0 20px 28px;

      .form-check.dsider-check-checkbox {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }

        .form-check-input[type=checkbox] {
          background-color: transparent;

          &:checked {
            background-color: transparent;
            background-image: url(../images/checkbox-checked-transparent.svg);
          }
        }
      }
    }

    &:first-child {
      .accordion-button {
        padding-top: 0;
      }
    }

    &:last-child {
      border: none;
    }
  }
}

.cursor-pointer {
  cursor: pointer !important;
}
